import HOR from 'redux/HOR';
import { ActionTypes } from 'redux/utils/actionCreator';

const { pipeHigherOrderReducers, withLoadable, withResetState } = HOR;
export const GAMELIST_ACTION_TYPES = new ActionTypes('GAMELIST').addAT().listAT().getActionTypes();

const initialState = {
  games: ''
};
const handleAction = {
  [GAMELIST_ACTION_TYPES.GET.SUCCESS]: (state, params) => ({
    ...state,
    games: params.list || '',
    totalAmount: params.totalAmount || 0
  }),
  [GAMELIST_ACTION_TYPES.POST.SUCCESS]: (state) => state
};

const reducer = (state = initialState, action) =>
  handleAction[action.type] ? handleAction[action.type](state, action.payload) : state;

export default pipeHigherOrderReducers(
  withResetState(GAMELIST_ACTION_TYPES.RESET_STATE, initialState),
  withLoadable({
    isLoadingActionType: [GAMELIST_ACTION_TYPES.GET.START, GAMELIST_ACTION_TYPES.POST.START],
    successActionType: [GAMELIST_ACTION_TYPES.GET.SUCCESS, GAMELIST_ACTION_TYPES.POST.SUCCESS],
    errorActionType: [GAMELIST_ACTION_TYPES.GET.ERROR, GAMELIST_ACTION_TYPES.POST.ERROR]
  })
)(reducer);
