import React from 'react';
import './index.scss';

const Spinner = (props) => {
  const { color = 'white', size = '34' } = props;
  /*
    @color = 'white' | ...
  */
  return (
    <span className="spinner">
      <svg
        width={size}
        height={size}
        viewBox="0 0 34 34"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="17" cy="17" r="15" stroke={color} />
      </svg>
    </span>
  );
};
export default Spinner;
