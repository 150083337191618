/* eslint-disable react/no-array-index-key,react-hooks/exhaustive-deps */
import React, {useEffect, useRef, useState} from 'react';
import chatSvg from 'assets/img/arrowChat.svg';
import { useDispatch, useSelector } from 'react-redux';
import { socketSelector } from 'redux/selectors';
import { messageItems } from 'modules/chat/redux/selectors';
import { resetChatMessageStateAC, setChatMessagesAC } from 'modules/chat/redux/actions';
import moment from 'moment';
import { Message } from './message';
import './index.scss';

export const Chat = () => {
  const socket = useSelector(socketSelector);
  const dispatch = useDispatch();
  const [message, setMessage] = useState('');
  const messages = useSelector(messageItems);
  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    if (socket) {
      socket.on('messages', (data) => {
        dispatch(setChatMessagesAC(data));
        scrollToBottom();
      });
    }

    return () => {
      if (socket) {
        socket.off('messages');
        dispatch(resetChatMessageStateAC());
      }
    };
  }, [socket]);

  const handleChangeMessage = (e) => setMessage(e.target.value);

  const onSubmit = (e) => {
    e.preventDefault();
    if (socket && message.length) {
      socket.invoke('sendMessage', message);
      setMessage('');
    }
  };

  const dateTime = (time) =>
    moment
      .utc(time * 1000)
      .local()
      .format('LT');

  return (
    <div className="chat">
      <h3 className="chat__title">Чат</h3>
      <div className="chat__body">
        {messages.map((el, index) => (
          <Message
            key={`messageItem--${index}`}
            time={dateTime}
            author={el.author}
            content={el.text}
          />
        ))}
        <div ref={messagesEndRef} />
      </div>
      <form className="chat__form" onSubmit={onSubmit}>
        <input value={message} onChange={handleChangeMessage} type="text" className="chat__input" />
        <button type="submit" className="chat__submit">
          <img src={chatSvg} alt="" />
        </button>
      </form>
    </div>
  );
};
