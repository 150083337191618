import HOR from 'redux/HOR';
import { ActionTypes } from 'redux/utils/actionCreator';

const { pipeHigherOrderReducers, withLoadable, withResetState } = HOR;
export const SOCKET_ACTION_TYPES = new ActionTypes('SOCKET').listAT().getActionTypes();


const initialState = {
  connection: ''
};
const stateHandler = (state, params) => ({ ...state, ...params });
const handleAction = {
  [SOCKET_ACTION_TYPES.GET.SUCCESS]: stateHandler,
  [SOCKET_ACTION_TYPES.GET.ERROR]: (state) => ({ ...state, ...initialState })
};

const reducer = (state = initialState, action) =>
  handleAction[action.type] ? handleAction[action.type](state, action.payload) : state;

export default pipeHigherOrderReducers(
  withResetState(SOCKET_ACTION_TYPES.RESET_STATE, initialState),
  withLoadable({
    isLoadingActionType: [SOCKET_ACTION_TYPES.GET.START],
    successActionType: [SOCKET_ACTION_TYPES.GET.SUCCESS],
    errorActionType: [SOCKET_ACTION_TYPES.GET.ERROR]
  })
)(reducer);
