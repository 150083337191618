import HOR from 'redux/HOR';
import { ActionTypes } from 'redux/utils/actionCreator';

const { pipeHigherOrderReducers, withLoadable, withResetState } = HOR;

export const VOTE_AREAS_ACTION_TYPES = new ActionTypes('VOTE_AREAS').listAT().getActionTypes();
const initialState = {
  infoVoteAreas: {}
};

const handleAction = {
  [VOTE_AREAS_ACTION_TYPES.GET.SUCCESS]: (state, params) => ({
      ...state,
      infoVoteAreas: params
  })
};

const reducer = (state = initialState, action) =>
  handleAction[action.type] ? handleAction[action.type](state, action.payload) : state;

export default pipeHigherOrderReducers(
  withResetState(VOTE_AREAS_ACTION_TYPES.RESET_STATE, initialState),
  withLoadable({
    isLoadingActionType: [VOTE_AREAS_ACTION_TYPES.GET.START],
    successActionType: [VOTE_AREAS_ACTION_TYPES.GET.SUCCESS],
    errorActionType: [VOTE_AREAS_ACTION_TYPES.GET.ERROR]
  })
)(reducer);
