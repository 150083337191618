import HOR from 'redux/HOR';
import { ActionTypes } from 'redux/utils/actionCreator';

const { pipeHigherOrderReducers, withLoadable, withResetState } = HOR;
export const GAME_USER_ID_ACTION_TYPES = new ActionTypes('GAME_USER_ID').addAT().getActionTypes();

const initialState = {
  playerId: '',
  totalNumberMoves: '',
  totalPlayingTime: ''
};
const handleAction = {
  [GAME_USER_ID_ACTION_TYPES.POST.SUCCESS]: (state, params) => ({
    ...state,
    ...params
  })
};

const reducer = (state = initialState, action) =>
  handleAction[action.type] ? handleAction[action.type](state, action.payload) : state;

export default pipeHigherOrderReducers(
  withResetState(GAME_USER_ID_ACTION_TYPES.RESET_STATE, initialState),
  withLoadable({
    isLoadingActionType: [GAME_USER_ID_ACTION_TYPES.POST.START],
    successActionType: [GAME_USER_ID_ACTION_TYPES.POST.SUCCESS],
    errorActionType: [GAME_USER_ID_ACTION_TYPES.POST.ERROR]
  })
)(reducer);
