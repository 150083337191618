import React from 'react';
import { generateStateMap } from 'modules/game/utils';
import ReactTooltip from 'react-tooltip';

const gamePoint = (props) => {
  /*
    @actionStatus = start | tax | moving
  */
  const { active = false, pointInfo } = props;
  const { text, type, pathToIcon, indicatorValue, state } = pointInfo;
  const statusChanges = () => {
    switch (type) {
      case 'SALARY':
        return {
          statusClassName: 'game__point--start',
          titleMessage: 'Старт'
        };
      case 'PAYTAX':
        return {
          statusClassName: 'game__point--tax',
          titleMessage: 'Налог'
        };
      case 'CHANGECITY':
        return {
          statusClassName: 'game__point--moving',
          titleMessage: 'Переезд'
        };
      case 'UPSALARY':
        return {
          statusClassName: 'game__point--upsalary',
          titleMessage: 'Повышение'
        };

      default:
        return {
          statusClassName: '',
          titleMessage: ''
        };
    }
  };
  const { statusClassName, titleMessage } = statusChanges();
  const isActive = active ? 'active' : '';
  const isIndicator = type === 'PROBLEM';
  const isImage = pathToIcon ? `${process.env.REACT_APP_API_URL}${pathToIcon}` : '';
  return (
    <div className={`game__point ${statusClassName} ${isActive}`}>
      <div className="game__pointWrap">
        <div className="game__pointContent">
          <span className="game__pointImage">
            <img src={isImage} alt="" />
            {!!titleMessage && <div className="game__pointMessage">{titleMessage}</div>}
          </span>
          {isIndicator && (
            <div className={`game__pointStatus ${generateStateMap(state)}`}>{indicatorValue}</div>
          )}
        </div>
        <div
          className="game__pointText"
          data-place="bottom"
          data-for={`game-point--${text}`}
          data-tip=""
        >
          <span>{text}</span>
        </div>

        <ReactTooltip className="tooltip" id={`game-point--${text}`}>
          <span>{text}</span>
        </ReactTooltip>
      </div>
    </div>
  );
};

export default gamePoint;
