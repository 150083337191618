import React, { useEffect, useState } from 'react';
import ContentLoader from 'react-content-loader';
import { useDispatch, useSelector } from 'react-redux';
import { socketSelector, userSelector, withLoading } from 'redux/selectors';
import { headerInfoSelector } from 'modules/game/redux/header/selectors';
import iconUser from 'assets/img/personIcon.svg';
import useResize from 'hooks/useResize.hook';
import ReactDOM from 'react-dom';
import { GetProfileInfoSuccessAction, ResetAction } from 'modules/game/redux/profile/actions';
import Stats from './stats';
import ChangeCityButton from './changeCityButton';
import './index.scss';

const Profile = () => {
  const socket = useSelector(socketSelector);
  const { firstName, surname, photoPath } = useSelector(userSelector);
  const headerInfo = useSelector(headerInfoSelector);
  const { isLoading } = useSelector(withLoading('user'));
  const { isMobile, isTablet } = useResize();
  const [isOpen, setIsOpen] = useState(false);

  const dispatch = useDispatch();
  useEffect(() => {
    if (socket) {
      socket.on('playerInfo', (data) => {
        dispatch(GetProfileInfoSuccessAction(data));
      });
    }
    return () => {
      if (socket) socket.off('playerInfo');
      dispatch(ResetAction());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket]);

  const generateFullName = () => {
    if (surname) {
      return `${firstName} ${surname}`;
    }
    return firstName;
  };
  const generateAvatar = () => {
    if (photoPath) {
      return (
        <img
          src={`${process.env.REACT_APP_API_URL}${photoPath}`}
          alt=""
          className="profile__image"
        />
      );
    }
    return <img src={iconUser} alt="" className="profile__image" />;
  };

  const handleToggle = () => setIsOpen(!isOpen);
  const isActiveClass = isOpen ? 'active' : '';

  return (
    <div className="profile">
      <div className="profile__header">
        <div className="profile__imageBox">
          {isLoading ? (
            <ContentLoader style={{ width: '100%', height: '100%' }}>
              <circle cx="50%" cy="50%" r="50%" />
            </ContentLoader>
          ) : (
            generateAvatar()
          )}
        </div>
        {isLoading ? (
          <ContentLoader style={{ width: '100%', height: '22px' }}>
            <rect x="9" y="4" rx="0" ry="0" width="100%" height="22" />
          </ContentLoader>
        ) : (
          <>{generateFullName()}</>
        )}

        {(isMobile || isTablet) && (
          <button
            type="button"
            className={`profile__open ${isActiveClass}`}
            onClick={handleToggle}
          />
        )}
      </div>
      {isMobile || isTablet ? (
        isOpen && (
          <div className="profile__box--mobile">
            <div className="profile__info">
              <Stats />
            </div>
            {headerInfo?.event === 'MOVE' && <ChangeCityButton />}
          </div>
        )
      ) : (
        <>
          <div className="profile__info">
            <Stats />
          </div>
          {headerInfo?.event === 'MOVE' && <ChangeCityButton />}
        </>
      )}
      {isOpen && ReactDOM.createPortal(<div className="city__overlay" />, document.body)}
    </div>
  );
};

export default Profile;
