import React from 'react';
import './index.scss';

const ArrowBack = ({ onClick }) => (
  <button type="button" onClick={onClick} className="arrowBack">
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="16" cy="16" r="15.5" stroke="#5D6A84" />
      <path
        d="M11.6464 15.6464C11.4512 15.8417 11.4512 16.1583 11.6464 16.3536L14.8284 19.5355C15.0237 19.7308 15.3403 19.7308 15.5355 19.5355C15.7308 19.3403 15.7308 19.0237 15.5355 18.8284L12.7071 16L15.5355 13.1716C15.7308 12.9763 15.7308 12.6597 15.5355 12.4645C15.3403 12.2692 15.0237 12.2692 14.8284 12.4645L11.6464 15.6464ZM20 15.5L12 15.5V16.5H20V15.5Z"
        fill="#5D6A84"
      />
    </svg>
  </button>
);

export default ArrowBack;
