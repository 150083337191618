import React, { useEffect } from 'react';
import { Route } from 'react-router-dom';
import { useAuth } from 'hooks/useAuth.hook';
import { SERVICES_URLS } from 'constants/SERVICES_URLS';
import { useDispatch } from 'react-redux';
import { GetUserAction, ResetAction } from 'redux/actions/user';

const PrivateRoute = (props) => {
  const { token } = useAuth();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(GetUserAction());
    return () => {
      dispatch(ResetAction());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  if (token) {
    return <Route {...props} />;
  }
  return (
    <Route
      component={() => {
        window.location.href = SERVICES_URLS.auth;
        return null;
      }}
    />
  );
};

export default PrivateRoute;
