import API from 'api/restApiService';

class GameList {
  getGameList = () => API.get(`/api/games?quantity=50`);

  enterInGame = ({ gameId, connectionId }) =>
    API.post(`/api/play-games/enter/${gameId}`, { connectionId });

  registrationInGame = (id) => API.post(`/api/games/registration/${id}`);

  pauseGame = (id) => API.put(`/api/games-admin/pause/${id}`);

  resumeGame = (id) => API.put(`/api/games-admin/resume/${id}`);
}

export default new GameList();
