import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import ActionStatus from 'modules/game/components/stepAction/actionStatus';
import Button from 'components/common/button';
import { useSelector } from 'react-redux';
import { getPublicInfoAction } from 'modules/game/redux/publicAction/selectors';
import { socketSelector } from 'redux/selectors';
import { generateDescription } from 'modules/game/utils';
import Timer from 'components/timer'
import { headerInfoSelector } from 'modules/game/redux/header/selectors'

export const PublicContentAction = () => {
  const socket = useSelector(socketSelector);
  const data = useSelector(getPublicInfoAction);
  const headerInfo = useSelector(headerInfoSelector);
  const [isActiveModal, setIsActiveModal] = useState(false);

  useEffect(() => {
    setIsActiveModal(data.active);
  }, [data]);

  const generateVariants = (variants) =>
    variants?.map((variant) => {
      const effects = variant?.effects?.map((effect) => generateDescription(effect));

      const generateTitle = () => {
        if (variant.skipPublicHearing) {
          return (
            <div className="modal__solutionTitle red">Решение пропустить: {effects.length}</div>
          );
        }
        return <div className="modal__solutionTitle success">Решение посетить: {effects}</div>;
      };

      return (
        <div key={`solution-public_${variant.id}`} className="modal__solution">
          {generateTitle()}
          <div className="modal__solutionDesc">{variant?.description}</div>
        </div>
      );
    });

  const colorBtn = (isSkip) => (isSkip ? 'red' : '');

  const handleSubmitVariant = (id) => () => {
    if (socket) {
      socket.invoke('chooseActionPublicHearingAnnouncement', id);
      setIsActiveModal(false);
    }
  };

  const generateButton = (variants) =>
    variants?.map((variant, index) => (
      <Button
        key={`button-action_${variant.id}`}
        disabled={variant.disabled}
        color={colorBtn(variant.skipPublicHearing)}
        onClick={handleSubmitVariant(variant.id)}
        filled={index === 1}
      >
        {variant.buttonText}
      </Button>
    ));

  return (
    <Modal
      overlayClassName="modal"
      closeTimeoutMS={300}
      className="modal__frame"
      isOpen={isActiveModal}
    >
      <div className="modal__content">
        <ActionStatus title="Публичные слушания" value={'!'} />
        <img
          src={`${process.env.REACT_APP_API_URL || ''}${data.picturePath}`}
          alt=""
          className="modal__image"
        />
      </div>

      <div className="modal__text">
        <div className="modal__subtitle">{data.title}</div>
        <p className="modal__desc">{data.description}</p>
      </div>

      <div className="modal__solutions">{generateVariants(data.actions)}</div>

      <div className="modal__buttons">{generateButton(data.actions)}</div>

      <div className="modal__timer">
        <span>Сделайте ход </span>
        <Timer expiryTimestamp={headerInfo?.correctEndTimeEvent} className="modal__timer--value" />
      </div>
    </Modal>
  );
};
