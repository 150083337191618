import React from 'react';
import ContentLoader from 'react-content-loader';

const SkeletonGameItem = () => (
  <div className="chooseGame__item">
    <div className="chooseGame__skeleton">
      <ContentLoader style={{ width: '100%', height: '22px' }}>
        <rect x="9" y="4" rx="0" ry="0" width="100%" height="22" />
      </ContentLoader>
    </div>
  </div>
);

export default SkeletonGameItem;
