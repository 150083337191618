import {numWord} from "utils/generators";

export const generateIndicatorClass = (indicator) => {
  switch (indicator) {
    case 'UP':
      return 'up';
    case 'DOWN':
      return 'down';
    case 'NONE':
      return '';
    default:
      return '';
  }
};

export const generateStateStatus = (state) => {
  switch (state) {
    case 'NEGATIVE':
      return 'red';

    case 'NEUTRAL':
      return 'yellow';

    default:
      return '';
  }
};

export const generateStateMap = (state) => {
  switch (state) {
    case 'NEGATIVE':
      return 'error';

    case 'NEUTRAL':
      return 'warning';

    case 'POSITIVE':
      return 'success';

    default:
      return '';
  }
};

export const generateDescription = ({ unit, type, value }) => {
  let resultUnit = '';
  let word = '';
  switch (unit) {
    case 'PERCENT':
      resultUnit = '%';
      break;
    case 'VALUE':
      resultUnit = ' Z';
      break;
    case 'MOVING':
      resultUnit = numWord(value, [' клетка', ' клетки', ' клеток']);
      break;
    default:
      break;
  }
  switch (type) {
    case 'MOVE':
      word = 'на карте';
      break;
    case 'BALANCE':
      if (value < 0) word = 'из баланса';
      else word = 'к балансу';
      break;
    case 'INCOME':
      if (value < 0) word = 'из дохода';
      else word = 'к доходу';
      break;
    default:
      break;
  }
  const newValue = value > 0 ? `+${value}` : value;
  return `(${newValue}${resultUnit} ${word})`;
};
