/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import useNotification from 'hooks/useNotification.hook';
import ChooseGame from 'pages/chooseGame';
import Notification from 'modules/notifications/components';
import GamePage from 'pages/game';
import NoMatch from 'pages/noMatch';
import PrivateRoute from 'components/router/privateRoute';
import { APP_ROUTES } from 'constants/APP_ROUTES';
import { useAuth } from 'hooks/useAuth.hook';
import { createSocketConnection, socketConnectionErrorAction } from 'redux/actions/socket';
import { socketSelector } from 'redux/selectors';

const Layout = () => {
  const { token } = useAuth();
  const dispatch = useDispatch();
  const socket = useSelector(socketSelector);

  useEffect(() => {
    if (token) {

      dispatch(createSocketConnection(token));
    }
  }, []);
  useEffect(() => {
    if (socket) {
      socket.onclose(() => {
        dispatch(socketConnectionErrorAction());
      });
    }
  }, [socket]);
  useNotification('socket', false);
  return (
    <>
      <Notification />
      <Router basename={process.env.REACT_APP_ROUTE_PREFIX || ''}>
        <Switch>
          <PrivateRoute exact path={APP_ROUTES.choosegame} component={ChooseGame} />
          <PrivateRoute exact path={APP_ROUTES.game} component={GamePage} />
          <Route exact path="*" component={NoMatch} />
        </Switch>
      </Router>
    </>
  );
};

export default Layout;
