/* eslint-disable */
import { useEffect, useState } from 'react';
import { breakPoints } from 'constants/BREAKPOINTS';

const useResize = (breakPointsCustom = breakPoints) => {
  const [activeBreakPoint, setActiveBreakPoint] = useState('');
  const [width, setWidth] = useState(window.innerWidth);

  const handleSubscribe = () => setWidth(window.innerWidth);

  useEffect(() => {
    window.addEventListener('resize', handleSubscribe);

    return () => window.removeEventListener('resize', handleSubscribe);
  }, []);

  const sortArr = (isMobileFirst) =>
    breakPointsCustom.breakpoints.sort((a, b) =>
      isMobileFirst ? a.value - b.value : a.value + b.value
    );

  useEffect(() => {
    if (breakPointsCustom.isMobileFirst) {
      sortArr(breakPointsCustom.isMobileFirst).forEach((el) => {
        if (width >= el.value) {
          setActiveBreakPoint(el.name);
        }
      });
    } else {
      sortArr(breakPointsCustom.isMobileFirst).forEach((el) => {
        if (width <= el.value) {
          setActiveBreakPoint(el.name);
        }
      });
    }
  }, [width]);

  const isMobile = activeBreakPoint === 'mobile';
  const isTablet = activeBreakPoint === 'tablet';

  return { activeBreakPoint, isMobile, isTablet };

};

export default useResize;
