/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { GameEndCity } from './gameEndCity';
import { GameEndWinners } from './gameEndWinners';

export const GameEndContent = ({ data }) => {
  const [isOpen, setIsOpen] = useState(false);

  const close = () => setIsOpen(false);
  const open = () => setIsOpen(true);

  useEffect(() => {
    if (data) {
      open();
    }
  }, [data]);

  return (
    <Modal
      onRequestClose={close}
      overlayClassName="modal"
      closeTimeoutMS={300}
      className="modal__frame end"
      isOpen={isOpen}
    >
      <h2 className="modal-end__title">Игра окончена</h2>

      <div className="modal-end__wrap">
        <div className="modal__endCities">
          {data?.cities?.map((el, index) => (
            <GameEndCity key={`gameEnd-sta--${index}`} data={el} />
          ))}
        </div>
        <GameEndWinners infoWinners={data?.achievements} />
      </div>

      <button type="button" className="modal-end__close" onClick={close}>
        Понятно
      </button>
    </Modal>
  );
};
