import HOR from 'redux/HOR';
import { ActionTypes } from 'redux/utils/actionCreator';

export const NOTIFICATIONS_ACTION_TYPES = new ActionTypes('NOTIFICATIONS', [
  'OPEN'
]).getActionTypes();

const { withResetState } = HOR;
/*
    var @status = 'danger' | 'warning' | 'success'
*/
const initialState = {
  status: '',
  message: '',
  title: ''
};

const handleAction = {
  [NOTIFICATIONS_ACTION_TYPES.OPEN]: (state, params) => ({ ...state, ...params })
};

const reducer = (state = initialState, action) =>
  handleAction[action.type] ? handleAction[action.type](state, action.payload) : state;

export default withResetState(NOTIFICATIONS_ACTION_TYPES.RESET_STATE, initialState)(reducer);
