import React from 'react';
import ContentLoader from 'react-content-loader';
import ReactTooltip from 'react-tooltip';
import ZIcon from 'components/common/zIcon';

import { generateIndicatorClass } from '../../utils';

const CityStats = (props) => {
  const { stats } = props;
  const { mayorInfo, population, balance, taxSystemInfo } = stats;
  const balanceValue =
    typeof balance?.value === 'number'
      ? parseFloat(balance?.value.toFixed(2)).toLocaleString('ru')
      : '';

  const tooltipContent = () => (
    <>
      <p>{taxSystemInfo?.description?.value}</p>
      <ul>
        {taxSystemInfo?.description?.list.map((item) => (
          <li key={item.value}>
            {item.valueFrom ? (
              <>
                От {item.valueFrom} <ZIcon />
                &nbsp;-{' '}
              </>
            ) : (
              ''
            )}
            {item.valueTo ? (
              <>
                До {item.valueTo} <ZIcon />
                &nbsp; -{' '}
              </>
            ) : (
              ''
            )}
            {item.value} %
          </li>
        ))}
      </ul>
    </>
  );
  return (
    <div className="city__stats">
      <div className="city__statsItem">
        <span className="city__statsName">Мэр</span>
        <span className="city__statsValue">
          {mayorInfo?.name || (
            <span className="city__statsValue">
              <ContentLoader style={{ width: '100%', height: '22px' }}>
                <rect x="9" y="4" rx="0" ry="0" width="100%" height="22" />
              </ContentLoader>
            </span>
          )}
        </span>
      </div>
      <div className="city__statsItem">
        <span className="city__statsName">Население</span>
        <span className={`city__statsValue ${generateIndicatorClass(population?.indicator)}`}>
          {typeof population?.value === 'number' ? (
            `${population?.value} чел.`
          ) : (
            <span className="city__statsValue">
              <ContentLoader style={{ width: '100%', height: '22px' }}>
                <rect x="9" y="4" rx="0" ry="0" width="100%" height="22" />
              </ContentLoader>
            </span>
          )}
        </span>
      </div>
      <div className="city__statsItem">
        <span className="city__statsName">Казна</span>
        <span className={`city__statsValue ${generateIndicatorClass(balance?.indicator)}`}>
          {typeof balance?.value === 'number' ? (
            <>
              {`${balanceValue} МЛН`} <ZIcon />
            </>
          ) : (
            <span className="city__statsValue">
              <ContentLoader style={{ width: '100%', height: '22px' }}>
                <rect x="9" y="4" rx="0" ry="0" width="100%" height="22" />
              </ContentLoader>
            </span>
          )}
        </span>
      </div>
      <div className="city__statsItem">
        <span className="city__statsName">Налог</span>
        <span data-place="bottom" data-for={'tax-tooltip'} data-tip="" className="city__statsValue">
          {taxSystemInfo?.name || (
            <ContentLoader style={{ width: '100%', height: '22px' }}>
              <rect x="9" y="4" rx="0" ry="0" width="100%" height="22" />
            </ContentLoader>
          )}
        </span>
        <ReactTooltip className="tooltip" id={'tax-tooltip'}>
          {tooltipContent()}
        </ReactTooltip>
      </div>
    </div>
  );
};

export default CityStats;
