import React from 'react';
import Cookies from 'js-cookie';
import { SERVICES_URLS } from 'constants/SERVICES_URLS';

import { IconExit } from './iconExit';

import './index.scss';

export const ExitButton = () => {
  const handleExit = () => {
    Cookies.remove('token', { path: '/' });
    window.location.href = SERVICES_URLS.auth;
  };

  return (
    <button type="button" className="button--exit" onClick={handleExit}>
      <span>ВЫЙТИ</span>
      <IconExit />
    </button>
  );
};
