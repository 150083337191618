import { useEffect, useState } from 'react';
import Cookies from 'js-cookie';

export const useAuth = () => {
  const [token, setToken] = useState(Cookies.get('token') || '');
  useEffect(() => {
    const localToken = Cookies.get('token');
    if (localToken) {
      setToken(localToken);
    }
  }, []);



  return { token };
};
