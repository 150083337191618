import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { headerInfoSelector } from 'modules/game/redux/header/selectors';
import { GetHeaderInfoSuccessAction, ResetAction } from 'modules/game/redux/header/actions';
import { socketSelector, userSelector } from 'redux/selectors';
import Timer from 'components/timer';
import Logo from 'components/logo';
import './index.scss';
import clockIcon from 'assets/img/clock.svg';
import calendarIcon from 'assets/img/calendar.svg';
import ContentLoader from 'react-content-loader';
import SERVICE_API from 'api';

const Header = () => {
  const [isPauseGame, setIsPauseGame] = useState(false);
  const socket = useSelector(socketSelector);
  const dispatch = useDispatch();
  const headerInfo = useSelector(headerInfoSelector);
  const user = useSelector(userSelector);

  const isCanPauseGame = (role) => role === 'administrator' || 'moderator';

  const controlGame = () => {
    if (isPauseGame) {
      SERVICE_API.GameList.resumeGame(sessionStorage.getItem('gameId'));
      setIsPauseGame(false);
    } else {
      SERVICE_API.GameList.pauseGame(sessionStorage.getItem('gameId'));
      setIsPauseGame(true);
    }
  };

  const correctExpireStamp = (data) => {
    const userCorrectDate = moment().valueOf();
    const startCorrectData = moment(data.startTimeEvent * 1000).valueOf();
    const difference = startCorrectData - userCorrectDate;
    const endTimeEvent = moment(data.endTimeEvent * 1000).valueOf();
    const correctEndTimeEvent = endTimeEvent - difference;
    return correctEndTimeEvent;
  };
  useEffect(() => {
    if (socket) {
      socket.on('headerInfo', (data) => {
        const correctEndTimeEvent = correctExpireStamp(data);
        dispatch(GetHeaderInfoSuccessAction({ ...data, correctEndTimeEvent }));
      });
    }
    return () => {
      if (socket) socket.off('headerInfo');
      dispatch(ResetAction());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket]);

  const timeForTheEndFrom = Math.floor(headerInfo?.totalPlayingTime / 60);

  const generateTimer = () => (
    <>
      {headerInfo?.endTimeEvent ? (
        <Timer className="header__timerValue" expiryTimestamp={headerInfo?.correctEndTimeEvent} />
      ) : (
        <ContentLoader
          style={{
            width: '60px',
            display: 'block',
            height: '40px',
            borderRadius: '4px',
            marginBottom: '10px'
          }}
        >
          <rect x="0" y="0" rx="0" ry="0" width="100%" height="100%" />
        </ContentLoader>
      )}
    </>
  );

  return (
    <header className="header">
      <div className="wrapper">
        <div className="header__box">
          <Logo />
          <div className="header__row">
            <div className="header__timer">
              {generateTimer()}
              <span className="header__timerText">
                {headerInfo?.textEvent || (
                  <ContentLoader
                    style={{
                      width: '40px',
                      display: 'block',
                      height: '22px',
                      borderRadius: '4px'
                    }}
                  >
                    <rect x="0" y="0" rx="0" ry="0" width="100%" height="22" />
                  </ContentLoader>
                )}
              </span>
            </div>

            {isCanPauseGame(user.role) && (
              <div className="header__control">
                <button
                  type="button"
                  className="header__control_button"
                  onClick={() => controlGame()}
                >
                  {isPauseGame ? 'Запуск' : 'Пауза'}
                </button>
              </div>
            )}

            <div className="header__info">
              <div className="header__infoItem">
                <img src={calendarIcon} alt="Длительность игры" />
                <span className="header__infoSteps">
                  {headerInfo?.totalNumberMoves ? (
                    `${headerInfo?.moveNumber || 0} ход из ${headerInfo?.totalNumberMoves}`
                  ) : (
                    <ContentLoader
                      style={{
                        width: '80px',
                        display: 'block',
                        height: '22px',
                        borderRadius: '4px'
                      }}
                    >
                      <rect x="0" y="0" rx="0" ry="0" width="100%" height="22" />
                    </ContentLoader>
                  )}
                </span>
              </div>
              <div className="header__infoItem">
                <img src={clockIcon} alt="Время до конца игры" />
                <span className="header__infoTime">
                  {headerInfo?.totalPlayingTime ? (
                    `Длительность игры ${timeForTheEndFrom} минут`
                  ) : (
                    <ContentLoader
                      style={{
                        width: '100px',
                        display: 'block',
                        height: '22px',
                        borderRadius: '4px'
                      }}
                    >
                      <rect x="0" y="0" rx="0" ry="0" width="100%" height="22" />
                    </ContentLoader>
                  )}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
