import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import Notifications from 'components/notification'
import { NotificationResetAction } from '../redux/actions'

const NotificationView = (props) => {
  const { onClose, isCloseTimeout = true } = props;
  const dispatch = useDispatch();

  const closed = () =>
    setTimeout(() => {
      onClose();
    }, 10000);

  useEffect(() => {
    if (isCloseTimeout) {
      closed();
    }
    return () => {
      dispatch(NotificationResetAction());
      if (isCloseTimeout) {
        clearTimeout(closed());
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return <Notifications {...props} />;
};

export default NotificationView;
