export const breakPoints = {
  breakpoints: [
    {
      name: 'desktop',
      value: 1920
    },
    {
      name: 'smallDesktop',
      value: 1440
    },
    {
      name: 'tablet',
      value: 768
    },
    {
      name: 'mobile',
      value: 500
    }
  ],
  isMobileFirst: false
};
