export const generateElemsByNumber = (val, elem) => {
  const arrayDot = [];
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < val; i++) {
    arrayDot.push(elem);
  }
  return arrayDot;
};

/*
Algorithm for conjugation of nouns under numerals
@value = number
@words = [word, word, word]
*/
export const numWord = (value, words) => {
  const val = Math.abs(value) % 100;
  const num = val % 10;
  if (val > 10 && val < 20) return words[2];
  if (num > 1 && num < 5) return words[1];
  if (num === 1) return words[0];
  return words[2];
};


export const activeClassSolution = (state) => {
  switch (state) {
    case "NEGATIVE":
      return "negative";

    case "NEUTRAL":
      return "neutral";

    case "POSITIVE":
      return "positive";

    default:
      return ""
  }
};
