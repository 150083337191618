import {ActionTypes} from "redux/utils/actionCreator";


export const TAB_BAR_ACTION_TYPES = new ActionTypes("TAB_BAR", ["CHANGE_TAB"]).getActionTypes();

const initialState = {
  activeTab: 0
}

const handleAction = {
  [TAB_BAR_ACTION_TYPES.CHANGE_TAB]: (state, params) => ({
    ...state,
    activeTab: params
  })
}

const tabBarReducer = (state = initialState, action) =>
    handleAction[action.type] ? handleAction[action.type](state, action.payload) : state;


export default tabBarReducer;