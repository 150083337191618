import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import ContentLoader from 'react-content-loader';
import { useDispatch, useSelector } from 'react-redux';
import { socketSelector } from 'redux/selectors';
import { GetCityInfoSuccessAction } from 'modules/game/redux/city/actions';
import { cityInfoSelector } from 'modules/game/redux/city/selectors';
import useResize from 'hooks/useResize.hook';
import Rating from '../../../../components/rating';
import CityStats from './cityStats';
import './index.scss';

const City = () => {
  const socket = useSelector(socketSelector);
  const cityInfo = useSelector(cityInfoSelector);
  const dispatch = useDispatch();
  const { isMobile, isTablet } = useResize();
  const [isOpen, setIsOpen] = useState(false);
  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (socket) {
      socket.on('cityInfo', (data) => {
        dispatch(GetCityInfoSuccessAction(data));
      });
      return () => {
        socket.off('cityInfo');
      };
    }
  }, [socket]);

  const { name, rating, ...cityStats } = cityInfo;

  const handleToggleStats = () => setIsOpen(!isOpen);
  const isActiveClass = isOpen ? 'active' : '';

  return (
    <div className="city">
      <div className="city__info">
        <div className="city__header">
          <h2 className="city__name">
            {name || (
              <ContentLoader style={{ width: '100px', display: 'block', height: '22px' }}>
                <rect x="0" y="0" rx="0" ry="0" width="100%" height="22" />
              </ContentLoader>
            )}
          </h2>
          <div className="city__rating">
            <Rating activeStars={rating?.value} />
          </div>

          {(isMobile || isTablet) && (
            <button
              type="button"
              className={`city__openInfo ${isActiveClass}`}
              onClick={handleToggleStats}
            />
          )}
        </div>
        {isMobile || isTablet ? (
          isOpen && <CityStats stats={cityStats} />
        ) : (
          <CityStats stats={cityStats} />
        )}
      </div>
      {isOpen && ReactDOM.createPortal(<div className="city__overlay" />, document.body)}
    </div>
  );
};

export default City;
