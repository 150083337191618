import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Header from 'modules/game/components/header';
import Game from 'modules/game/components';
import Council from 'modules/council/components';

import { enterInGameAction } from 'modules/game/redux/enterGame/actions';
import { socketSelector, withLoading } from 'redux/selectors';
import './index.scss';

const GamePage = ({match}) => {
  const dispatch = useDispatch();
  const socket = useSelector(socketSelector);
  const { error } = useSelector(withLoading('enterGame'));
  const history = useHistory();
  useEffect(() => {
    if (socket) {
      dispatch(
        enterInGameAction({
          gameId: match.params.id,
          connectionId: socket.connection.connectionId
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket]);
  useEffect(() => {
    if (error) {
      history.push('/');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  return (
    <main className="main">
      <Header />
      <Game />
      <Council />
      
    </main>
  );
};

export default GamePage;
