/* eslint-disable react/no-array-index-key */
import React from 'react';
import './index.scss';
import { useDispatch, useSelector } from 'react-redux';
import { changeActiveTab } from 'redux/actions/tabBar';
import { getActiveTabBar } from 'redux/selectors';
import { tabBarsArray } from 'constants/TAB_BAR';

const TabBar = () => {
  const dispatch = useDispatch();
  const activeTab = useSelector(getActiveTabBar);

  const handleChangeActiveTab = (index) => () => dispatch(changeActiveTab(index));
  const isSelectedTab = (index) => (activeTab === index ? 'active' : '');
  const isDisabled = (disabled) => (disabled ? 'disabled' : '');

  return (
    <div className="tabbar">
      {tabBarsArray.map((item, index) => (
        <button
          type="button"
          key={`tab-bar-item--${index}`}
          disabled={item.disabled}
          className={`tabbar__btn ${isSelectedTab(index)} ${isDisabled(item.disabled)}`}
          onClick={handleChangeActiveTab(index)}
        >
          {item.icon}
        </button>
      ))}
    </div>
  );
};

export default TabBar;
