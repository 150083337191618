import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { socketSelector } from 'redux/selectors';
import MovingContent from './movingContent';
import 'swiper/swiper.scss';

const Moving = () => {
  const socket = useSelector(socketSelector);
  const [changeCityInfo, setChangeCityInfo] = useState('');

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (socket) {
      socket.on('changeCityInfo', (data) => {
        // TODO: to redux
        setChangeCityInfo(data);
      });
      return () => {
        socket.off('changeCityInfo');
      };
    }
  }, [socket]);

  return <MovingContent data={changeCityInfo} />;
};

export default Moving;
