import React from 'react';

const ActionStatus = ({ title, value, color = '', isPublic }) => {
  const classList = `modal__status ${color} ${isPublic ? 'modal__status--public' : ''}`;
  return (
    <div className={classList}>
      <div className="modal__statusTitle">{title}</div>
      <span className="modal__statusValue">{value}</span>
    </div>
  );
};

export default ActionStatus;
