import { call, put, takeEvery } from 'redux-saga/effects';
import SERVICE_API from 'api';
import { GAMELIST_ACTION_TYPES } from 'modules/gameList/redux/reducer';

function* getGameList() {
  try {
    const { data } = yield call(SERVICE_API.GameList.getGameList);

    yield put({
      type: GAMELIST_ACTION_TYPES.GET.SUCCESS,
      payload: data
    });
  } catch ({ response }) {
    yield put({
      type: GAMELIST_ACTION_TYPES.GET.ERROR,
      payload: response.data
    });
  }
}

function* regestrationOnGame(action) {
  try {
    const { data } = yield call(SERVICE_API.GameList.registrationInGame, action.payload);
    yield call(getGameList);
    yield put({
      type: GAMELIST_ACTION_TYPES.POST.SUCCESS,
      data
    });
  } catch ({ response }) {
    yield put({
      type: GAMELIST_ACTION_TYPES.POST.ERROR,
      payload: response.data
    });
  }
}

export function* GameListSaga() {
  yield takeEvery(GAMELIST_ACTION_TYPES.POST.START, regestrationOnGame);
  yield takeEvery(GAMELIST_ACTION_TYPES.GET.START, getGameList);
}
