import { call, put, takeEvery } from 'redux-saga/effects';
import SERVICE_API from 'api';
import { GAME_USER_ID_ACTION_TYPES } from 'modules/game/redux/enterGame/reducer';

function* enterInGame(action) {
  try {
    const { data } = yield call(SERVICE_API.GameList.enterInGame, action.payload);
    yield put({
      type: GAME_USER_ID_ACTION_TYPES.POST.SUCCESS,
      payload: data
    });
  } catch ({ response }) {
    yield put({
      type: GAME_USER_ID_ACTION_TYPES.POST.ERROR,
      payload: response.data
    });
  }
}

export function* GameUserSaga() {
  yield takeEvery(GAME_USER_ID_ACTION_TYPES.POST.START, enterInGame);
}
