export const generateStateStatus = (state) => {
  switch (state) {
    case 'NEGATIVE':
      return 'danger';
    case 'NEUTRAL':
      return 'warning';
    case 'POSITIVE':
      return 'good';
    default:
      return '';
  }
};
