import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { socketSelector } from 'redux/selectors';
import ChooseMayorContent from './chooseMayorContent';
import 'swiper/swiper.scss';

const ChooseMayor = () => {
  const socket = useSelector(socketSelector);
  const [mayoralElectionInfo, setMayoralElectionInfo] = useState('');

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (socket) {
      socket.on('mayoralElectionInfo', (data) => {
        // TODO: to redux
        setMayoralElectionInfo(data);
      });
      return () => {
        socket.off('mayoralElectionInfo');
      };
    }
  }, [socket]);

  return <ChooseMayorContent data={mayoralElectionInfo} />;
};

export default ChooseMayor;
