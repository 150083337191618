import React from 'react';

const GamesEmpty = () => (
  <div className="chooseGame__empty">
    <div className="chooseGame__emptyContent">
      <h3 className="chooseGame__emptyTitle">Игру пока не создали</h3>
      <div className="chooseGame__emptyText">
        Ознакомьтесь с нашим сайтом и пройдите тест
      </div>
      <a href={process.env.REACT_APP_PLATFORM_URL} className="chooseGame__emptyLink">
        На сайт
      </a>
    </div>
  </div>
);

export default GamesEmpty;
