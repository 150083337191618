import { HubConnectionBuilder, HubConnection } from '@microsoft/signalr';
import { call, put, takeLatest, apply } from 'redux-saga/effects';
import { SOCKET_ACTION_TYPES } from 'redux/reducers/socket';

function createSocket(token) {
  return new HubConnectionBuilder()
    .withUrl(`${process.env.REACT_APP_API_URL || ''}/hub`, {
      accessTokenFactory: () => token
    })
    .build();
}

function* getSocketConnection(action) {
  try {
    const socket = yield call(createSocket, action.payload);
    yield apply(socket, HubConnection.prototype.start, []);

    yield put({
      type: SOCKET_ACTION_TYPES.GET.SUCCESS,
      payload: { connection: socket }
    });
  } catch (err) {
    yield put({
      type: SOCKET_ACTION_TYPES.GET.ERROR,
      payload: {
        detail:
          'Соединение с игрой прервано, попробуйте перезагрузить страницу или обратитесь к администратору'
      }
    });
  }
}
export function* SocketSaga() {
  yield takeLatest(SOCKET_ACTION_TYPES.GET.START, getSocketConnection);
}
