import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import RouterLink from 'components/router/link';
import { withLoading } from 'redux/selectors';
import Spinner from 'components/spinner';
import { RegisterOnGameAction } from '../redux/actions';

export const RegisteredButton = (props) => {
  const { id } = props;
  return (
    <RouterLink to={`/${id}/game`}>
      <button type="button" className="chooseGame__col chooseGame__col--sign">
        Вход
      </button>
    </RouterLink>
  );
};

export const UnregisteredButton = (props) => {
  const { id } = props;
  const dispatch = useDispatch();
  const { isLoading } = useSelector(withLoading('gameList'));
  const [buttonLoader, setButtonLoader] = useState(false);
  useEffect(() => {
    if (!isLoading) setButtonLoader(isLoading);
  }, [isLoading]);
  const onClick = () => {
    setButtonLoader(true);
    dispatch(RegisterOnGameAction(id));
  };
  return (
    <button onClick={onClick} type="button" className="chooseGame__col chooseGame__col--sign">
      {buttonLoader ? <Spinner /> : 'Записаться на игру'}
    </button>
  );
};
