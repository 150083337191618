import React from 'react';
import { useSelector } from 'react-redux';
import ZIcon from 'components/common/zIcon';
import { profileInfoSelector } from 'modules/game/redux/profile/selectors';
import ContentLoader from 'react-content-loader';
import { generateIndicatorClass } from '../../utils';

const Stats = () => {
  const playerInfo = useSelector(profileInfoSelector);

  const balanceValue =
    typeof playerInfo?.balance?.value === 'number'
      ? parseFloat(playerInfo?.balance?.value.toFixed(2)).toLocaleString('ru')
      : '';
  const incomeValue = playerInfo?.income?.value
    ? parseFloat(playerInfo?.income?.value.toFixed(2)).toLocaleString('ru')
    : '';
  return (
    <div className="profile__stats">
      <div className="profile__statsItem">
        <span className="profile__statsName">Личный счёт</span>
        {playerInfo?.balance ? (
          <span
            className={`profile__statsValue ${generateIndicatorClass(
              playerInfo?.balance?.indicator
            )}`}
          >
            {balanceValue} <ZIcon />
          </span>
        ) : (
          <span className="profile__statsValue">
            <ContentLoader style={{ width: '100%', height: '22px' }}>
              <rect x="9" y="4" rx="0" ry="0" width="100%" height="22" />
            </ContentLoader>
          </span>
        )}
      </div>
      <div className="profile__statsItem">
        <span className="profile__statsName">Налог</span>
        {playerInfo?.tax ? (
          <span
            className={`profile__statsValue ${generateIndicatorClass(playerInfo?.tax?.indicator)}`}
          >
            {playerInfo?.tax?.value} %
          </span>
        ) : (
          <span className="profile__statsValue">
            <ContentLoader style={{ width: '100%', height: '22px' }}>
              <rect x="9" y="4" rx="0" ry="0" width="100%" height="22" />
            </ContentLoader>
          </span>
        )}
      </div>
      <div className="profile__statsItem">
        <span className="profile__statsName">Зарплата</span>
        {playerInfo?.income ? (
          <span
            className={`profile__statsValue ${generateIndicatorClass(
              playerInfo?.income?.indicator
            )}`}
          >
            {incomeValue} <ZIcon />
          </span>
        ) : (
          <span className="profile__statsValue">
            <ContentLoader style={{ width: '100%', height: '22px' }}>
              <rect x="9" y="4" rx="0" ry="0" width="100%" height="22" />
            </ContentLoader>
          </span>
        )}
      </div>
    </div>
  );
};

export default Stats;
