/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { socketSelector } from 'redux/selectors';
import { GetPublicAuditionInfoAction } from 'modules/game/redux/publicAction/actions';
import { PublicContentAction } from './publicContentAction';

const PublicAuditionAction = () => {
  const socket = useSelector(socketSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    if (socket) {
      socket.on('publicHearingAnnouncement', (data) => {
        dispatch(GetPublicAuditionInfoAction(data));
      });
    }

    return () => {
      if (socket) {
        socket.off('publicHearingAnnouncement');
      }
    };
  }, [socket]);

  return <PublicContentAction />;
};

export default PublicAuditionAction;
