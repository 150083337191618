import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { AreaItem } from 'modules/game/components/voteAreasAction/areaItem';
import { useSelector } from 'react-redux';
import { socketSelector } from 'redux/selectors';
import { getInfoVoteAreas } from 'modules/game/redux/voteAreas/selectors';
import useResize from 'hooks/useResize.hook';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Pagination } from 'swiper/core';

export const VoteAreasContent = () => {
  const socket = useSelector(socketSelector);
  const data = useSelector(getInfoVoteAreas);
  const { isMobile, isTablet } = useResize();
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    setIsActive(data.active);
  }, [data]);

  const generateIsMobile = () => {
    const handleVote = (id) => () => {
      socket.invoke('choosePriorityObject', id);
      setIsActive(false);
    };

    if (isTablet || isMobile) {
      SwiperCore.use([Pagination]);
      return (
        <Swiper
          className="vote__slider"
          spaceBetween={10}
          slidesPerView="auto"
          pagination={{ clickable: true }}
        >
          {data?.priorityObjects?.map((el) => (
            <SwiperSlide key={`vote--${el?.id}`} className="vote__slide">
              <AreaItem info={el} onClick={handleVote(el?.id)} />
            </SwiperSlide>
          ))}
        </Swiper>
      );
    }

    return data?.priorityObjects?.map((el) => (
      <AreaItem key={`vote--${el?.id}`} info={el} onClick={handleVote(el?.id)} />
    ));
  };

  return (
    <Modal
      overlayClassName="modal"
      closeTimeoutMS={300}
      className="modal__frame modal__frame--vote-areas"
      isOpen={isActive}
    >
      <h2 className="modal__title modal__title--vote">
        Рейтинговое голосование по отбору общественных территорий, подлежащих благоустройству
      </h2>

      <div className="modal__vote-areas">{generateIsMobile()}</div>
    </Modal>
  );
};
