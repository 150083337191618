import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withLoading } from 'redux/selectors';
import {
  NotificationOpenAction,
  NotificationResetAction
} from 'modules/notifications/redux/actions';

const useNotification = (module, isTimeoutClose) => {
  const dispatch = useDispatch();
  const { error } = useSelector(withLoading(module));

  useEffect(() => {
    if (error) {
      dispatch(
        NotificationOpenAction({
          status: 'danger',
          message: error,
          isTimeoutClose
        })
      );
    }
    return () => {
      dispatch(NotificationResetAction());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);
};

export default useNotification;
