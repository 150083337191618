import React from 'react';
import { generateStateStatus } from '../../utils';
import './index.scss';

const CouncilItem = (props) => {
  const {
    children,
    active = false,
    indicator = 0,
    status = 'POSITIVE',
    isVoted = false,
    ...other
  } = props;
  const statusClass = generateStateStatus(status);
  const activeClass = active ? 'active' : '';
  return (
    <button type="button" className={`councilItem ${statusClass} ${activeClass}`} {...other}>
      <div className="councilItem__text">{children}</div>
      <span className="councilItem__status">{indicator}</span>
      {isVoted && <div className="councilItem__voted" />}
    </button>
  );
};

export default CouncilItem;
