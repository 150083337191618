import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import Effect from 'components/effect';
import Button from 'components/common/button';
import PersonCard from 'components/personCard';
import { useSelector } from 'react-redux';
import { socketSelector } from 'redux/selectors';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Pagination } from 'swiper/core';
import useResize from 'hooks/useResize.hook';
import 'swiper/swiper.scss';
import 'swiper/components/pagination/pagination.scss';

const ChooseMayorContent = (props) => {
  const { data } = props;
  const [active, setActive] = useState(false);
  const socket = useSelector(socketSelector);
  const { isMobile, isTablet } = useResize();

  const close = () => {
    setActive(false);
  };
  const open = () => {
    setActive(true);
  };
  useEffect(() => {
    if (data?.active) {
      open();
    } else {
      close();
    }
  }, [data]);

  const generateEffects = (effects) =>
    effects?.map((item) => {
      const buff = item.value > 0 ? 'buff' : 'debuff';
      const newValue = item.value > 0 ? `+${item.value}` : item.value;
      return (
        <Effect key={item.problemName} type={buff}>
          {newValue} {item.problemName}
        </Effect>
      );
    });
  const handleVote = (id) => () => {
    if (socket) socket.invoke('voteMayor', id);
    close();
  };

  const generateCard = () => {
    if (isTablet || isMobile) {
      SwiperCore.use([Pagination]);
      return (
        <Swiper
          className="mayor__slider"
          spaceBetween={10}
          slidesPerView="auto"
          pagination={{ clickable: true }}
        >
          {data?.mayors?.map((item) => (
            <SwiperSlide key={`mayor-card-${item.id}`} className="mayor__slide">
              <div className="personCard">
                <div className="personCard__header">
                  <PersonCard firstName={item.name} photoPath={item.photoPath} />
                </div>
                <div className="personCard__description">{item.quote}</div>
                <div className="personCard__info">{generateEffects(item.effects)}</div>
                <Button onClick={handleVote(item.id)}>Голосовать</Button>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      );
    }
    return data?.mayors?.map((item) => (
      <div className="personCard" key={`mayor-card-${item.id}`}>
        <div className="personCard__header">
          <PersonCard firstName={item.name} photoPath={item.photoPath} />
        </div>
        <div className="personCard__description">{item.quote}</div>
        <div className="personCard__info">{generateEffects(item.effects)}</div>
        <Button onClick={handleVote(item.id)}>Голосовать</Button>
      </div>
    ));
  };

  return (
    <Modal overlayClassName="modal" closeTimeoutMS={300} className="modal__frame" isOpen={active}>
      <h2 className="modal__title">Для того чтобы игра началась вам нужно выбрать Мэра города.</h2>
      <div className="mayor">
        <div className="mayor__row">{generateCard()}</div>
        <button onClick={close} type="button" className="mayor__closeBtn">
          Воздержаться
        </button>
      </div>
    </Modal>
  );
};

export default ChooseMayorContent;
