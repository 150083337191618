import HOR from 'redux/HOR';
import { ActionTypes } from 'redux/utils/actionCreator';

const { pipeHigherOrderReducers, withLoadable, withResetState } = HOR;
export const DICE_ACTION_TYPES = new ActionTypes('DICE', ['SET_IS_OPEN_DICE'])
  .listAT()
  .getActionTypes();

const initialState = {
  diceValue: { value: 0 },
  isOpenModal: false
};

const handleAction = {
  [DICE_ACTION_TYPES.GET.SUCCESS]: (state, params) => ({
    ...state,
    diceValue: { value: params.value },
    isNextStep: params.nextStep
  }),
  [DICE_ACTION_TYPES.SET_IS_OPEN_DICE]: (state, params) => ({
    ...state,
    isOpenModal: params
  })
};

const reducer = (state = initialState, action) =>
  handleAction[action.type] ? handleAction[action.type](state, action.payload) : state;

export default pipeHigherOrderReducers(
  withResetState(DICE_ACTION_TYPES.RESET_STATE, initialState),
  withLoadable({
    isLoadingActionType: [DICE_ACTION_TYPES.GET.START],
    successActionType: [DICE_ACTION_TYPES.GET.SUCCESS],
    errorActionType: [DICE_ACTION_TYPES.GET.ERROR]
  })
)(reducer);
