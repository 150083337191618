import {ActionTypes} from "redux/utils/actionCreator";
import HOR from "redux/HOR";


const { pipeHigherOrderReducers, withResetState, withLoadable } = HOR;

export const CHAT_ACTION_TYPES = new ActionTypes("CHAT", ["SET_MESSAGES"]).listAT().getActionTypes();

const initialState = {
  messages: [],
  isLoading: false,
  error: {},
  success: null
};

const handleAction = {
  [CHAT_ACTION_TYPES.GET.SUCCESS]: (state, params) => ({
    ...state,
    messages: [...state.messages, ...params.messages]
  }),
  [CHAT_ACTION_TYPES.RESET_STATE]: (state) => ({
    ...state,
    messages: [],
  })
}


const chatReducer = (state = initialState, action) =>
  handleAction[action.type] ? handleAction[action.type](state, action.payload) : state;

export default pipeHigherOrderReducers(
  withResetState(CHAT_ACTION_TYPES.RESET_STATE, initialState),
  withLoadable({
    isLoadingActionType: [CHAT_ACTION_TYPES.GET.START],
    successActionType: [CHAT_ACTION_TYPES.GET.SUCCESS],
    errorActionType: [CHAT_ACTION_TYPES.GET.ERROR]
  })
)(chatReducer);
