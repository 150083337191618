import React from 'react';
import { generateElemsByNumber } from 'utils/generators';
import './index.scss';

const Rating = (props) => {
  const { activeStars = 0, stars = 5 } = props;
  const starsTemplate = generateElemsByNumber(stars, '*');
  const mapRating = () =>
    starsTemplate.map((_, idx) => {
      const active = idx < activeStars ? 'active' : '';
      return (
        // eslint-disable-next-line react/no-array-index-key
        <span className={`rating__star ${active}`} key={`star-${idx}`}>
          <svg viewBox="0 0 9 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4.5 0L5.69027 3.49768L8.77975 3.80041L6.42589 6.26482L7.14503 9.94959L4.5 7.975L1.85497 9.94959L2.57411 6.26482L0.220246 3.80041L3.30973 3.49768L4.5 0Z" />
          </svg>
        </span>
      );
    });
  return <div className="rating">{mapRating()}</div>;
};

export default Rating;
