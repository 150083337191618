import React from 'react';
import Modal from 'react-modal';
import useResize from 'hooks/useResize.hook';

const WarningResizeModal = () => {
  const { isTablet } = useResize();


  return (
    <Modal
      overlayClassName="modal"
      closeTimeoutMS={300}
      className={`modal__frame`}
      isOpen={isTablet}
    >
      <div className="modal__text">Для продолжения игры - разверните окно браузера.</div>
    </Modal>
  );
};

export default WarningResizeModal;
