/* eslint-disable no-plusplus,react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getActiveTabBar, socketSelector } from 'redux/selectors';
import Dice from 'components/dice';
import { Chat } from 'modules/chat/components';
import { News } from 'modules/news';
import useResize from 'hooks/useResize.hook';
import TabBar from 'components/common/tabBar';
import { Tabs } from 'components/tabs';
import { Tab } from 'components/tabs/Tab';
import { NewsMenu } from 'components/newsMenu';
import { ResetDiceAction, SetDiceValueAction } from 'modules/game/redux/diceGame/actions';
import PublicAuditionAction from 'modules/game/components/publicAuditionAction';
import PublicAudition from 'modules/game/components/publicAudition';
import VoteAreasAction from 'modules/game/components/voteAreasAction';
import { Swiper, SwiperSlide } from 'swiper/react';
import WarningResizeModal from 'modules/game/components/warningResize';
import GameEndTimer from 'modules/game/components/gameEndTimer';
import DiceModal from 'modules/game/components/diceModal';
import StepAction from './stepAction';
import Greeting from './greeting';
import ChooseMayor from './chooseMayor';
import Moving from './moving';
import GameMap from './map';
import City from './city';
import Profile from './profile';
import { CouncilEnd } from './councilEnd';
import { GameEnd } from './gameEnd';
import './index.scss';
import 'swiper/swiper.scss';

const Game = () => {
  const dispatch = useDispatch();
  const socket = useSelector(socketSelector);
  const [diceInfo, setDiceInfo] = useState('');
  const { isMobile, isTablet } = useResize();
  const activeTabBar = useSelector(getActiveTabBar);
  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (socket) {
      socket.on('diceInfo', ({ value, nextStep }) => {
        dispatch(SetDiceValueAction({ value, nextStep }));
      });
    }
    return () => {
      if (socket) {
        socket.off('diceInfo');
        dispatch(ResetDiceAction());
      }
    };
  }, [socket]);

  return (
    <>
      <div className="game">
        <div className="wrapper">
          <div className="game__box">
            {(isMobile || isTablet) && (
              <Swiper className="game__info--mobile" spaceBetween={10} slidesPerView="auto">
                <SwiperSlide className="game__itemCity">
                  <City />
                </SwiperSlide>
                <SwiperSlide className="game__itemUser">
                  <Profile />
                </SwiperSlide>
              </Swiper>
            )}

            <div className="game__col">
              <div className="game__main">
                <GameMap />
                <div className="game__info">
                  <div className="game__city">
                    <City />
                  </div>
                  <div className="game__dice">
                    <Dice />
                  </div>
                </div>
              </div>
              <div className="game__bottom">{!(isTablet || isMobile) && <News />}</div>
            </div>
            <div className="game__aside">
              <div className="game__profile">
                <Profile />
              </div>
              <div className="game__chat">{!(isTablet || isMobile) && <Chat />}</div>
            </div>
          </div>
        </div>
      </div>
      <Greeting />
      <Moving />
      <ChooseMayor />
      <StepAction />
      <GameEnd />
      <CouncilEnd />
      <PublicAuditionAction />
      <PublicAudition />
      <VoteAreasAction />
      <GameEndTimer />

      {(isTablet || isMobile) && (
        <>
          <Tabs activeTab={activeTabBar}>
            <Tab index={1}>
              <News />
            </Tab>
            <Tab index={2}>
              <Chat />
            </Tab>
            <Tab index={3}>
              <NewsMenu />
            </Tab>
          </Tabs>

          <TabBar />
          <DiceModal />
        </>
      )}

      <WarningResizeModal />
    </>
  );
};

export default Game;
