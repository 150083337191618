import React, { useEffect } from 'react';
import Modal from 'react-modal';
import Dice from 'components/dice';
import { useDispatch, useSelector } from 'react-redux';
import { getDiceValue, getIsOpenDice } from 'modules/game/redux/diceGame/selectors';
import { SetIsOpenDiceAction } from 'modules/game/redux/diceGame/actions';

const DiceModal = () => {
  const dispatch = useDispatch();
  const isOpenModal = useSelector(getIsOpenDice);
  const { isNextStep, diceValue } = useSelector(getDiceValue);

  useEffect(() => {
    if (isNextStep && diceValue) {
      dispatch(SetIsOpenDiceAction(true));
    }
  }, [diceValue]);

  return (
    <Modal
      overlayClassName="modal"
      closeTimeoutMS={300}
      className="modal__dice"
      isOpen={isOpenModal}
    >
      <Dice />
    </Modal>
  );
};

export default DiceModal;
