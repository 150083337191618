import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { socketSelector } from 'redux/selectors';
import StepActionContent from './actionContent';

const StepAction = () => {
  const socket = useSelector(socketSelector);
  const [problemInfo, setProblemInfo] = useState('');

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (socket) {
      socket.on('problemInfo', (data) => {
        // TODO: to redux
        setProblemInfo(data);
      });
      return () => {
        socket.off('problemInfo');
      };
    }
  }, [socket]);

  return <StepActionContent data={problemInfo} />;
};

export default StepAction;
