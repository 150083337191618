/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { socketSelector } from 'redux/selectors';
import CouncilEndContent from './councilEndContent';

export const CouncilEnd = () => {
  const socket = useSelector(socketSelector);
  const [councilEndInfo, setCouncilEndInfo] = useState('');

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (socket) {
      socket.on('cityCouncilResult', (data) => {
        // TODO: to redux
        setCouncilEndInfo(data);
      });
      return () => {
        socket.off('cityCouncilResult');
      };
    }
  }, [socket]);

  return <CouncilEndContent data={councilEndInfo} />;
};
