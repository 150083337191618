import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { socketSelector } from 'redux/selectors';
import GamePoint from './gamePoint';
import MapImage from './mapImage';
import { gameMap } from '../../constants/gamMap';

const GameMap = () => {
  const socket = useSelector(socketSelector);
  const [map, setMap] = useState('');
  const [active, setActive] = useState(0);
  // eslint-disable-next-line consistent-return
  /*
    Получение массива передвижения для анимации хода
    prevPosition = number - текущий индекс клетки
    newPosition = number - новое индекс клетки
  */

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (socket) {
      socket.on('mapInfo', (data) => {
        // TODO: to redux
        setMap(() => {
          setActive(data?.activeCellNumber);
          // Анимация хода
          //   const moveArray = mapWay(prev?.activeCellNumber, data?.activeCellNumber);
          //   const an = setInterval(() => {
          //     setActive(moveArray.shift());
          //     if (moveArray.length === 0) clearInterval(an);
          //   }, 1000);
          return data;
        });
      });
      return () => {
        socket.off('mapInfo');
      };
    }
  }, [socket]);

  const generateMap = () => {
    const newMap = map?.cellsInfo || gameMap;
    // TODO убрать slice
    return newMap.map((point, idx) => {
      const activePoint = active === idx;
      return <GamePoint key={`game-point-${point?.text}`} pointInfo={point} active={activePoint} />;
    });
  };
  return (
    <div className="game__map">
      {generateMap()}
      {/* : PASHA прелоадер делать не надо его не будет
      но вот у тебя карта меняет высоту от размера картинки */}
      <MapImage src={map?.picturePath} />
    </div>
  );
};

export default GameMap;
