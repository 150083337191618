import { CITY_ACTION_TYPES } from 'modules/game/redux/city/reducer';

export const GetCityInfoSuccessAction = (payload) => ({
  type: CITY_ACTION_TYPES.GET.SUCCESS,
  payload
});

export const ResetAction = () => ({
  type: CITY_ACTION_TYPES.RESET_STATE
});
