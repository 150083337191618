import HOR from 'redux/HOR';
import { ActionTypes } from 'redux/utils/actionCreator';

const { pipeHigherOrderReducers, withLoadable, withResetState } = HOR;
export const USER_ACTION_TYPES = new ActionTypes('USER').listAT().getActionTypes();

const initialState = {
  id: '',
  login: '',
  role: '',
  surname: '',
  firstName: '',
  email: '',
  phone: '',
  photoPath: ''
};
const stateHandler = (state, params) => ({ ...state, ...params });
const handleAction = {
  [USER_ACTION_TYPES.GET.SUCCESS]: stateHandler
};

const reducer = (state = initialState, action) =>
  handleAction[action.type] ? handleAction[action.type](state, action.payload) : state;

export default pipeHigherOrderReducers(
  withResetState(USER_ACTION_TYPES.RESET_STATE, initialState),
  withLoadable({
    isLoadingActionType: [USER_ACTION_TYPES.GET.START],
    successActionType: [USER_ACTION_TYPES.GET.SUCCESS],
    errorActionType: [USER_ACTION_TYPES.GET.ERROR]
  })
)(reducer);
