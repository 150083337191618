import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { socketSelector } from 'redux/selectors';

export const CouncilDonate = () => {
  const socket = useSelector(socketSelector);
  const [valueDonate, setValueDonate] = useState('');
  const [errorDonate, setErrorDonate] = useState('');

  useEffect(() => {
    if (socket) {
      socket.on('donateToTreasury', (data) => {
        setErrorDonate(data);
      });
    }

    return () => {
      if (socket) socket.off('donateToTreasury');
    };
  }, [socket]);

  const changeValueDonate = (e) => setValueDonate(e.target.value);

  const submitValueDonate = () => {
    if (socket && valueDonate.length) {
      socket.invoke('donateToTreasury', +valueDonate);
      setErrorDonate('');
      setValueDonate('');
    }
  };

  const isDisabledSubmit = !valueDonate.length;
  const isErrorValueDonate = errorDonate.isError ? 'error' : '';

  return (
    <div className="council__donate">
      <div className={`council__donateField ${isErrorValueDonate}`}>
        <input
          type="number"
          min="1"
          className="council__donateValue"
          onChange={changeValueDonate}
          value={valueDonate}
        />
        {!!errorDonate?.message && (
          <div className="council__donateValue--error">{errorDonate?.message}</div>
        )}
        <div className="council__donateCurrency">Z</div>
      </div>

      <button
        onClick={submitValueDonate}
        type="button"
        className="council__donateSubmit"
        disabled={isDisabledSubmit}
      >
        Пожертвовать в казну{' '}
      </button>
    </div>
  );
};
