export const gameMap = [
  {
    text: 'Старт',
    type: 'SALARY'
  },
  {
    text: '2',
    type: ''
  },
  {
    text: '3',
    type: ''
  },
  {
    text: '4',
    type: ''
  },
  {
    text: '5',
    type: ''
  },
  {
    text: '6',
    type: ''
  },
  {
    text: '7',
    type: ''
  },
  {
    text: '8',
    type: ''
  },
  {
    text: '9',
    type: ''
  },
  {
    text: '10',
    type: ''
  },
  {
    text: '11',
    type: ''
  },
  {
    text: '12',
    type: ''
  },
  {
    text: '13',
    type: ''
  },
  {
    text: '14',
    type: ''
  },
  {
    text: '15',
    type: ''
  },
  {
    text: '16',
    type: ''
  },
  {
    text: '17',
    type: ''
  },
  {
    text: '18',
    type: ''
  },
  {
    text: '19',
    type: ''
  },
  {
    text: '20',
    type: ''
  },
  {
    text: '21',
    type: ''
  },
  {
    text: '22',
    type: ''
  },
  // {
  //   text: '23',
  //   type: ''
  // },
  // {
  //   text: '24',
  //   type: ''
  // }
];
