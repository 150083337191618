import React, { useState, useEffect } from 'react';
import bgLayer from 'assets/img/bg-game-layer.svg';

const MapImage = (props) => {
  const { src = '' } = props;
  const [mapImg, setMapImg] = useState(bgLayer);
  const mapInfoImage = src ? `${process.env.REACT_APP_API_URL || ''}${src}` : '';
  useEffect(() => {
    if (mapInfoImage) {
      setMapImg(mapInfoImage);
    }
  }, [mapInfoImage]);
  return <img className="game__mapImage" src={mapImg} alt="" />;
};

export default MapImage;
