/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { socketSelector } from 'redux/selectors';
import { GetAuditionInfoAction } from 'modules/game/redux/publicAudition/actions';
import { PublicAuditionContent } from './publicAuditionContent';

const PublicAudition = () => {
  const dispatch = useDispatch();
  const socket = useSelector(socketSelector);

  useEffect(() => {
    if (socket) {
      socket.on('publicHearing', (data) => {
        dispatch(GetAuditionInfoAction(data));
      });
    }

    return () => {
      if (socket) {
        socket.off('publicHearing');
      }
    };
  }, [socket]);

  return <PublicAuditionContent />;
};

export default PublicAudition;
