import HOR from 'redux/HOR';
import { ActionTypes } from 'redux/utils/actionCreator';

const { pipeHigherOrderReducers, withLoadable, withResetState } = HOR;
export const COUNCIL_ACTION_TYPES = new ActionTypes('COUNCIL', ['UPDATE_VOTE', 'UPDATE_BALANCE'])
  .listAT()
  .getActionTypes();

const initialState = {};
const handleAction = {
  [COUNCIL_ACTION_TYPES.GET.SUCCESS]: (state, params) => ({
    ...state,
    ...params
  }),
  [COUNCIL_ACTION_TYPES.UPDATE_VOTE]: (state, params) => {
    const inVote = state.vote?.indexOf(params);
    const addLimit =
      state?.vote?.length < state?.maxVotes ? [...state.vote, params] : [...state.vote];
    const newVote = inVote < 0 ? addLimit : state.vote.filter((item) => item !== params);
    return {
      ...state,
      vote: newVote
    };
  },
  [COUNCIL_ACTION_TYPES.UPDATE_BALANCE]: (state, params) => ({
    ...state,
    cityBalance: params.treasury
  })
};

const reducer = (state = initialState, action) =>
  handleAction[action.type] ? handleAction[action.type](state, action.payload) : state;

export default pipeHigherOrderReducers(
  withResetState(COUNCIL_ACTION_TYPES.RESET_STATE, initialState),
  withLoadable({
    isLoadingActionType: [COUNCIL_ACTION_TYPES.GET.START],
    successActionType: [COUNCIL_ACTION_TYPES.GET.SUCCESS],
    errorActionType: [COUNCIL_ACTION_TYPES.GET.ERROR]
  })
)(reducer);
