/* eslint-disable react/no-array-index-key */
import React from 'react';
import { newsMenuInfo } from 'modules/game/constants/newsMenu';


export const NewsMenu = () => (
  <div className="menu">
    {newsMenuInfo.map((el, index) => (
      <button
        key={`news-menu--${index}`}
        type="button"
        className="menu__btn active"
      >
        {el}
      </button>
    ))}
  </div>
);
