/* eslint-disable react/no-array-index-key,react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { NewsItem } from 'modules/news/components/newsItem';
import { NewsMenu } from 'components/newsMenu';
import { useDispatch, useSelector } from 'react-redux';
import { socketSelector } from 'redux/selectors';
import { cityInfoSelector } from 'modules/game/redux/city/selectors';
import { setNewsAC } from 'modules/news/redux/actions';
import { getCurrentCity, getDiffrentCity } from 'modules/news/redux/selectors';
import { Tab } from 'components/tabs/Tab';
import { Tabs } from 'components/tabs';
import useResize from 'hooks/useResize.hook';
import './index.scss'

export const News = () => {
  const dispatch = useDispatch();
  const socket = useSelector(socketSelector);
  const currentCity = useSelector(getCurrentCity);
  const diffrentCity = useSelector(getDiffrentCity);
  const [activeCity, setActiveCity] = useState(0);
  const newsRef = useRef();
  const { isMobile, isTablet } = useResize();

  const scrollToBottomChat = () => {
    const scroll = newsRef.current.scrollHeight - newsRef.current.clientHeight;

    newsRef.current.scrollTo(0, scroll);
  };

  useEffect(() => {
    if (socket) {
      socket.on('massMedia', (data) => {
        dispatch(setNewsAC(data));
        scrollToBottomChat();
      });
    }
    return () => {
      if (socket) socket.off('massMedia');
    };
  }, [socket]);

  useEffect(() => {
    scrollToBottomChat();
  }, [activeCity]);

  const handleChangeCity = (index) => () => setActiveCity(index);

  const isActiveCity = (index) => (activeCity === index ? 'active' : '');

  const generateCurrentCityTitle = (city) => (city?.mainMassMedia ? 'Cми' : city?.name);

  const generateNewsItems = (city) =>
    !!city?.news?.length &&
    city?.news?.map(({ sendDateTime, type, text }, index) => {
      const dateTime = moment
        .utc(sendDateTime * 1000)
        .local()
        .format('HH:mm');
      return <NewsItem key={`mediaItem--${index}`} time={dateTime} desc={text} type={type} />;
    });

  return (
    <>
      <div className="news">
        <div className="news__toggle">
          <button
            type="button"
            className={`news__btn ${isActiveCity(0)}`}
            onClick={handleChangeCity(0)}
          >
            {generateCurrentCityTitle(currentCity)}
          </button>

          <button
            type="button"
            className={`news__btn ${isActiveCity(1)}`}
            onClick={handleChangeCity(1)}
          >
            {generateCurrentCityTitle(diffrentCity)}
          </button>
        </div>
        <div className="news__body" ref={newsRef}>
          <Tabs activeTab={activeCity}>
            <Tab index={0}>{generateNewsItems(currentCity)}</Tab>
            <Tab index={1}>{generateNewsItems(diffrentCity)}</Tab>
          </Tabs>
        </div>
      </div>
      {!(isMobile || isTablet) && <NewsMenu />}
    </>
  );
};
