import { SOCKET_ACTION_TYPES } from 'redux/reducers/socket';

export const createSocketConnection = (payload) => ({
  type: SOCKET_ACTION_TYPES.GET.START,
  payload
});
export const socketConnectionErrorAction = () => ({
  type: SOCKET_ACTION_TYPES.GET.ERROR,
  payload: {
    detail:
      'Соединение с игрой прервано, попробуйте перезагрузить страницу или обратитесь к администратору'
  }
});
export const ResetAction = () => ({
  type: SOCKET_ACTION_TYPES.RESET_STATE
});
