import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Button from 'components/common/button';
import { socketSelector } from 'redux/selectors';
import Spinner from 'components/spinner';
import { CouncilSolution } from 'modules/council/components/councilSolution';
import { activeClassSolution } from 'utils/generators';
import ArrowBack from 'components/common/arrowback';
import useResize from 'hooks/useResize.hook';
import { councilInfoSelector } from '../../redux/selectors';
import { generateStateStatus } from '../../utils';
import './index.scss';

const CouncilInfo = (props) => {
  const { activeProblem, refList } = props;
  const socket = useSelector(socketSelector);
  const councilInfo = useSelector(councilInfoSelector);
  const data = councilInfo?.problems?.[activeProblem];
  const [fakeLoading, setFakeLoading] = useState(false);
  const [isOpenSolution, setIsOpenSolution] = useState(false);
  const { isMobile, isTablet } = useResize();

  useEffect(() => {
    setIsOpenSolution(true);
  }, [activeProblem]);

  useEffect(() => {
    if ((isTablet || isMobile) && isOpenSolution) {
      refList.current.style.display = 'none';
    } else {
      refList.current.style.display = 'flex';
    }
  }, [isOpenSolution]);

  const handleVote = () => {
    if (socket) socket.invoke('votesCityCouncil', councilInfo?.vote);
    setFakeLoading(true);
    setTimeout(() => {
      setFakeLoading(false);
    }, 1000);
  };

  const generateSolutions = () =>
    data?.problemSolutions?.map((item) => {
      const active = councilInfo?.vote?.includes(item.id) ? activeClassSolution(data?.state) : '';
      return <CouncilSolution key={item.id} infoSolution={item} isActive={active} />;
    });

  const handleCloseSolution = () => setIsOpenSolution(false);
  const problemStatusClass = generateStateStatus(data?.state);
  const isActiveClassMobile = isOpenSolution ? 'active' : '';

  return (
    <div className={`councilInfo ${isActiveClassMobile}`}>
      <div className="councilInfo__header">
        {(isMobile || isTablet) && <ArrowBack onClick={handleCloseSolution} />}
        <h2 className="councilInfo__title">{data?.name}</h2>
        <span className={`councilInfo__status ${problemStatusClass}`}>{data?.indicatorValue}</span>
      </div>
      <form className="councilInfo__list">
        <div className="councilInfo__listMain">{generateSolutions()}</div>
        <Button onClick={handleVote} style={{ float: 'right', width: '50%', maxHeight: '47px' }}>
          {fakeLoading ? <Spinner size={20} /> : 'Cохранить'}
        </Button>
      </form>
    </div>
  );
};

export default CouncilInfo;
