import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { socketSelector } from 'redux/selectors';
import ArrowBack from 'components/common/arrowback';
// import Spinner from 'components/spinner';
import ZIcon from 'components/common/zIcon';
import { CouncilDonate } from 'modules/council/components/councilDonate';
import {
  GetCouncilInfoSuccessAction,
  ResetAction,
  UpdateCouncilInfoAction
} from 'modules/council/redux/actions';
import { getProfileBalance } from 'modules/game/redux/profile/selectors';
import { councilInfoSelector } from 'modules/council/redux/selectors';
import CouncilItem from './councilItem';
import CouncilInfo from './councilInfo';

import './index.scss';

const Council = () => {
  const socket = useSelector(socketSelector);
  const councilInfo = useSelector(councilInfoSelector);
  const balanceUser = useSelector(getProfileBalance);
  const dispatch = useDispatch();
  const [isOpenCouncil, setIsOpenCouncil] = useState(false);
  const [activeProblem, setActiveProblem] = useState(0);
  const refCouncilList = useRef();
  useEffect(() => {
    if (socket) {
      socket.on('cityCouncilInfo', (data) => {
        dispatch(GetCouncilInfoSuccessAction(data));
      });

      socket.on('cityTreasury', (data) => {
        dispatch(UpdateCouncilInfoAction(data));
      });
    }
    return () => {
      if (socket) {
        socket.off('cityCouncilInfo');
        socket.off('cityTreasury');
      }
      dispatch(ResetAction());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket]);

  const open = () => {
    setIsOpenCouncil(true);
  };
  const close = () => {
    setIsOpenCouncil(false);
  };
  useEffect(() => {
    if (councilInfo?.active) {
      open();
    } else {
      close();
    }
  }, [councilInfo?.active]);
  const handleProblemClick = (idx) => () => {
    setActiveProblem(idx);
  };
  const isOpenClass = isOpenCouncil ? 'active' : '';
  const balanceValue = councilInfo?.cityBalance
    ? parseFloat(councilInfo?.cityBalance?.toFixed()).toLocaleString('ru')
    : '';
  const userBalanceValue = balanceUser
    ? parseFloat(balanceUser?.value?.toFixed()).toLocaleString('ru')
    : '';
  const generateProblems = () =>
    councilInfo?.problems?.length &&
    councilInfo?.problems?.map((item, idx) => {
      let isVoted = false;
      const isActive = idx === activeProblem;
      // eslint-disable-next-line consistent-return
      item.problemSolutions.forEach((solution) => {
        if (councilInfo?.vote?.includes(solution.id)) {
          isVoted = true;
          return false;
        }
      });
      return (
        <CouncilItem
          key={item.name}
          isVoted={isVoted}
          active={isActive}
          indicator={item.indicatorValue}
          status={item.state}
          onClick={handleProblemClick(idx)}
        >
          {item.name}
        </CouncilItem>
      );
    });
  return (
    <>
      {councilInfo?.active && (
        <div className={`council ${isOpenClass}`}>
          {!isOpenCouncil && (
            <button onClick={open} type="button" className="councilTrigger">
              <span className="councilTrigger__text">Городской совет</span>
            </button>
          )}
          <div className="council__box">
            <div className="wrapper">
              <div className="council__header">
                <div className="council__row">
                  <ArrowBack onClick={close} />
                  <h2 className="council__title">Городской совет</h2>
                </div>

                <div className="council__money">
                  <div className="council__purse">
                    В казне города
                    <span className="council__purseVal">
                      {`${balanceValue} МЛН`} <ZIcon />{' '}
                    </span>
                  </div>

                  <div className="council__purse">
                    Ваш бюджет
                    <span className="council__purseVal">
                      {userBalanceValue}
                      <ZIcon />
                    </span>
                  </div>
                  <CouncilDonate />
                </div>
              </div>
              <div className="council__body">
                <div className="council__status">
                  Вы выбрали решений проблем
                  <span className="council__statusValue">
                    {councilInfo?.vote?.length || 0}/{councilInfo?.maxVotes}
                  </span>
                </div>
                <div className="council__list" ref={refCouncilList}>
                  {generateProblems()}
                </div>
                <CouncilInfo activeProblem={activeProblem} refList={refCouncilList} />
              </div>
            </div>
          </div>

          {/* //TODO: спиннер */}
          {/* <div className="council__spinner"> */}
          {/*  <Spinner color="#0fb77f" size={80}/> */}
          {/* </div> */}
        </div>
      )}
    </>
  );
};

export default Council;
