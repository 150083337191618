/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import Rating from 'components/rating';
import { generateIndicatorClass } from 'modules/game/utils';
import Button from 'components/common/button';

import './index.scss';

const CouncilEndContent = ({ data }) => {
  const [isOpen, setIsOpen] = useState(false);

  const close = () => setIsOpen(false);
  const open = () => setIsOpen(true);

  useEffect(() => {
    if (data) {
      open();
    }
  }, [data]);

  const expensesValue = data?.expenses
    ? parseFloat(data?.expenses.toFixed(2)).toLocaleString('ru')
    : 0;

  const balanceValue = data?.balance
    ? parseFloat(data?.balance.toFixed(2)).toLocaleString('ru')
    : '';

  return (
    <Modal
      onRequestClose={close}
      overlayClassName="modal"
      closeTimeoutMS={300}
      className="modal__frame"
      isOpen={isOpen}
    >
      <h2 className="modal__title">Городской совет окончен</h2>
      <div className="modal__stats">
        <div className="modal__statsItem">
          <div className="modal__statsTitle">Было потрачено</div>
          <div className="modal__statsValue">
            {expensesValue}
            <span> &#90;</span>
          </div>
        </div>
        <div className="modal__statsItem">
          <div className="modal__statsTitle">Остаток в казне</div>
          <div className="modal__statsValue">
            {balanceValue} <span>&#90;</span>
          </div>
        </div>
        <div className="modal__statsItem">
          <div className="modal__statsTitle">Рейтинг города</div>
          <div
            className={`modal__statsValue modal__statsValue--rating ${generateIndicatorClass(
              data?.rating?.indicator
            )}`}
          >
            <Rating activeStars={data?.rating?.value} />
          </div>
        </div>
      </div>

      {data?.appliedProblemSolutions?.length > 0 ? (
        <div className="modal__problems">
          <h3 className="modal__problemsTitle">Решенные проблемы</h3>
          <div className="modal__problemsItems">
            {data?.appliedProblemSolutions?.map((el, index) => (
              <div key={`success-problem--${index}`} className={`modal__problem ${el.state}`}>
                <div className="modal__problemText">{el.description}</div>
                <div className="modal__problemValue">{el.indicatorValue}</div>
              </div>
            ))}
          </div>
        </div>
      ) : (
        <h3 className="modal__problemsTitle">Не было принято решений</h3>
      )}

      <Button modif="modal__done" onClick={close}>
        Понятно
      </Button>
    </Modal>
  );
};

export default CouncilEndContent;
