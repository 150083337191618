import React, { useEffect, useState } from 'react';
import Button from 'components/common/button';
import { useDispatch, useSelector } from 'react-redux';
import { socketSelector } from 'redux/selectors';
import Modal from 'react-modal';
// import Rubble from 'components/common/zIcon';
import { headerInfoSelector } from 'modules/game/redux/header/selectors';
import Timer from 'components/timer';
import { generateDescription } from 'modules/game/utils';
import { SetIsOpenDiceAction } from 'modules/game/redux/diceGame/actions';
import ActionStatus from './actionStatus';
import { generateStateStatus } from '../../utils';

const StepActionContent = (props) => {
  const { data } = props;
  const dispatch = useDispatch();
  const headerInfo = useSelector(headerInfoSelector);
  const socket = useSelector(socketSelector);
  const [active, setActive] = useState(false);
  useEffect(() => {
    if (data?.active) {
      if (headerInfo?.event === 'MOVE') {
        setActive(true);
        dispatch(SetIsOpenDiceAction(false));
      }
    } else {
      setActive(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);
  const color = generateStateStatus(data?.state);

  const generateVariants = () =>
    data?.actionsInfo?.map((item) => {
      const effects = item?.effects?.map((effect) => generateDescription(effect));
      return (
        <span key={`action_description-${item.id}`}>
          {item.description} {effects.join(', ')}
          <br />
        </span>
      );
    });

  const submitVariant = (id) => () => {
    if (socket) socket.invoke('chooseAction', id);
    setActive(false);
  };
  const generateActionButton = () =>
    data?.actionsInfo?.map((item, idx) => (
      <Button
        key={`action_button-${item.id}`}
        disabled={item.disabled}
        onClick={submitVariant(item.id)}
        color={color}
        filled={idx === 0}
      >
        {item.buttonText}
      </Button>
    ));
  return (
    // Цвета передаются в компонент ActionStatus : color="red"
    // Цвета передаются в компонент Button : color="red"
    // Компонент button принимает  в себя параметр filled  Для  заливки кнопки (стили)
    // Компонент button принимает  в себя параметр style  для описания стилей
    <Modal overlayClassName="modal" closeTimeoutMS={300} className={`modal__frame`} isOpen={active}>
      <div className="modal__content">
        <ActionStatus title={data.name} value={data.indicatorValue} color={color} />

        <img
          src={`${process.env.REACT_APP_API_URL || ''}${data?.picturePath}`}
          alt=""
          className="modal__image"
        />
      </div>

      <div className="modal__text modal__text--dark">{data?.description}</div>
      <div className="modal__text modal__text--dark">
        У вас есть выбор:
        <br /> {generateVariants()}
      </div>
      <div className="modal__buttons">{generateActionButton()}</div>
      <div className="modal__timer">
        <span>Сделайте ход </span>
        <Timer expiryTimestamp={headerInfo?.correctEndTimeEvent} className="modal__timer--value" />
      </div>
    </Modal>
  );
};

export default StepActionContent;
