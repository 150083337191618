import React from 'react';
import './index.scss';

const Notifications = (props) => {
  const { activeClass, title, message, onClose } = props;

  return (
    <div className={`message ${activeClass}`}>
      <div className="wrapper">
        <div className="message__box">
          <span className="message__title">{title}</span>
          <span className="message__text">{message}</span>
          <button className="message__close" type="button" onClick={onClose} />
        </div>
      </div>
    </div>
  );
};

export default Notifications;
