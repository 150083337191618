import React from 'react';
import { useSelector } from 'react-redux';
import { socketSelector } from 'redux/selectors';

const ChangeCityButton = (props) => {
  const socket = useSelector(socketSelector);
  const onClick = () => {
    if (socket) socket.invoke('getChangeCityInfo');
  };

  return (
    <button type="button" className="profile__changeCity" onClick={onClick} {...props}>
      <span>Переехать</span>
      <svg
        width="20"
        height="12"
        viewBox="0 0 20 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2.96639 9.0597C2.96639 7.9881 3.84677 7.1194 4.93277 7.1194C6.01878 7.1194 6.89916 7.9881 6.89916 9.0597H12.042C12.042 7.9881 12.9224 7.1194 14.0084 7.1194C15.0944 7.1194 15.9748 7.9881 15.9748 9.0597H18C18.5523 9.0597 19 8.61199 19 8.0597V6.73462C19 6.33429 18.7612 5.97254 18.3932 5.81515L14.7499 4.25733C14.5626 4.17724 14.4042 4.04186 14.2959 3.8693L12.7898 1.46856C12.6068 1.177 12.2868 1 11.9427 1H6.67407C6.34191 1 6.03142 1.16493 5.84547 1.44017L4.3226 3.69416C4.13664 3.96939 3.82616 4.13433 3.49399 4.13433H2C1.44772 4.13433 1 4.58204 1 5.13433V8.0597C1 8.61199 1.44772 9.0597 2 9.0597H2.96639Z"
          fill="#0FB77F"
        />
        <path
          d="M6.89916 9.0597C6.89916 10.1313 6.01878 11 4.93277 11C3.84677 11 2.96639 10.1313 2.96639 9.0597M6.89916 9.0597C6.89916 7.9881 6.01878 7.1194 4.93277 7.1194C3.84677 7.1194 2.96639 7.9881 2.96639 9.0597M6.89916 9.0597H12.042M2.96639 9.0597H2C1.44772 9.0597 1 8.61198 1 8.0597V5.13433C1 4.58204 1.44772 4.13433 2 4.13433H3.49399C3.82616 4.13433 4.13664 3.96939 4.3226 3.69416L5.84547 1.44017C6.03142 1.16493 6.34191 1 6.67407 1H11.9427C12.2868 1 12.6068 1.177 12.7898 1.46856L14.2959 3.8693C14.4042 4.04186 14.5626 4.17724 14.7499 4.25733L18.3932 5.81515C18.7612 5.97254 19 6.33429 19 6.73462V8.0597C19 8.61199 18.5523 9.0597 18 9.0597H15.9748M15.9748 9.0597C15.9748 10.1313 15.0944 11 14.0084 11C12.9224 11 12.042 10.1313 12.042 9.0597M15.9748 9.0597C15.9748 7.9881 15.0944 7.1194 14.0084 7.1194C12.9224 7.1194 12.042 7.9881 12.042 9.0597"
          stroke="#0FB77F"
        />
        <path d="M5 4L6.5 1.5H12.5L14 4H5Z" fill="white" />
      </svg>
    </button>
  );
};

export default ChangeCityButton;
