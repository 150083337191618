/* eslint-disable react/no-array-index-key */
import React from 'react';
import ReactTooltip from 'react-tooltip';

export const GameEndWinners = ({ infoWinners }) => {
  const tooktipContentLead = (text = '') => <>{text}</>;

  return (
    <div className="winners">
      <h3 className="winners__title">Список победителей</h3>
      <div className="winners__list">
        {infoWinners?.map((el, index) => (
          <div className="winners__item" key={`winners-${el.name}--${index}`}>
            <div
              className="winners__itemTitle"
              data-place="bottom"
              data-for={`lead-tooltip--${index}`}
              data-tip=""
            >
              {el?.name}
            </div>
            <div className="winners__itemValue">{el?.winnerName}</div>
            {el?.description && (
              <ReactTooltip className="tooltip" id={`lead-tooltip--${index}`}>
                {tooktipContentLead(el?.description)}
              </ReactTooltip>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};
