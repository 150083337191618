import { GAME_USER_ID_ACTION_TYPES } from 'modules/game/redux/enterGame/reducer';

export const enterInGameAction = (payload) => ({
  type: GAME_USER_ID_ACTION_TYPES.POST.START,
  payload
});

export const ResetAction = () => ({
  type: GAME_USER_ID_ACTION_TYPES.RESET_STATE
});
