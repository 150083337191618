import React from 'react';

export const tabBarsArray = [
  {
    key: 1,
    disabled: false,
    icon: (
      <svg
        width="30"
        height="25"
        viewBox="0 0 30 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12.1666 24.3333V15.8333H17.8333V24.3333H24.9166V13H29.1666L15 0.25L0.833313 13H5.08331V24.3333H12.1666Z"
          fill="#0DA66F"
        />
      </svg>
    )
  },
  {
    key: 2,
    disabled: false,
    icon: (
      <svg
        width="34"
        height="34"
        viewBox="0 0 34 34"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3 29V25.4837L6.63319 23.3469V15C6.54239 12.6762 7.09153 10.3703 8.22572 8.31294C9.35469 6.40485 11.2844 5.04992 13.5273 4.59059V1H20.4214V4.59059C24.8663 5.60188 27.3154 9.29788 27.3154 15V23.3469L30.949 25.4837L31 29H16.9743H3ZM28.7131 27.0862H5.23592L8.5898 24.9495V15C8.5898 10.0318 11.8814 6.74256 13.5273 6.25313H15.2976V3.04804H19.2104V6.25313H20.3284C24.8002 6.78731 25.2349 12.6186 25.3592 15V24.9495L28.7131 27.0862Z"
            fill="#0DA66F"
          />
          <path
            d="M14 30C14.0164 31.6559 15.3591 32.9919 17.015 33C17.1929 32.9964 17.3702 32.9763 17.5444 32.94H17.5579H17.5849H17.6074H17.6149C18.5776 32.7316 19.3814 32.073 19.7749 31.17C19.9266 30.7987 20.0031 30.401 19.9999 30H14Z"
            fill="#0DA66F"
          />
        </g>
        <defs>
          <clipPath id="clip0">
            <rect width="34" height="34" fill="white" />
          </clipPath>
        </defs>
      </svg>
    )
  },
  {
    key: 3,
    disabled: false,
    icon: (
      <svg
        width="30"
        height="30"
        viewBox="0 0 30 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M26.3333 0.833008H3.66665C2.10831 0.833008 0.833313 2.10801 0.833313 3.66634V29.1663L6.49998 23.4997H26.3333C27.8916 23.4997 29.1666 22.2247 29.1666 20.6663V3.66634C29.1666 2.10801 27.8916 0.833008 26.3333 0.833008ZM26.3333 20.6663H6.49998L3.66665 23.4997V3.66634H26.3333V20.6663Z"
          fill="#0DA66F"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M26.3333 23.4997V20.6663H6.49998L3.66665 23.4997H26.3333Z"
          fill="#0DA66F"
        />
      </svg>
    )
  },
  {
    key: 4,
    disabled: true,
    icon: (
      <svg
        width="30"
        height="30"
        viewBox="0 0 30 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15 29.1664C7.17954 29.1578 0.841932 22.8202 0.833344 14.9997V14.7164C0.98908 6.93112 7.39901 0.730971 15.1851 0.83428C22.9712 0.937589 29.2144 7.30562 29.1635 15.0923C29.1127 22.8789 22.7868 29.1648 15 29.1664ZM14.9773 26.333H15C21.257 26.3268 26.3251 21.2511 26.322 14.994C26.3189 8.73703 21.2457 3.66637 14.9887 3.66637C8.73166 3.66637 3.65847 8.73703 3.65534 14.994C3.65222 21.2511 8.72033 26.3268 14.9773 26.333ZM16.4167 23.4997H13.5833V20.6664H16.4167V23.4997ZM16.4167 19.2497H13.5833C13.5386 17.4047 14.4987 15.6808 16.0908 14.7475C17.0258 14.0307 17.8333 13.413 17.8333 12.1664C17.8333 10.6016 16.5648 9.33303 15 9.33303C13.4352 9.33303 12.1667 10.6016 12.1667 12.1664H9.33334V12.0389C9.35612 10.0144 10.4572 8.15579 12.2219 7.16327C13.9865 6.17074 16.1467 6.19504 17.8886 7.22701C19.6304 8.25899 20.6895 10.1419 20.6667 12.1664C20.5652 13.695 19.7454 15.0851 18.4567 15.9135C17.2918 16.6447 16.5367 17.8795 16.4167 19.2497Z"
          fill="#0DA66F"
        />
      </svg>
    )
  }
];
