import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { socketSelector } from 'redux/selectors';
import Modal from 'react-modal';
import Button from 'components/common/button';
import Rating from 'components/rating';
import Rubble from 'components/common/zIcon';
import { profileInfoSelector } from 'modules/game/redux/profile/selectors';
import bgLayer from 'assets/img/bg-game-layer.svg';
import Timer from 'components/timer';
import { headerEndTimeEventSelector } from 'modules/game/redux/header/selectors';

const MovingContent = (props) => {
  const { data } = props;
  const [active, setActive] = useState(false);
  const socket = useSelector(socketSelector);
  const playerInfo = useSelector(profileInfoSelector);
  const headerEndTime = useSelector(headerEndTimeEventSelector);
  const buttonDisabled = playerInfo?.balance?.value < data?.movingCost;
  const close = () => {
    setActive(false);
  };
  const open = () => {
    setActive(true);
  };
  useEffect(() => {
    if (data?.active) {
      open();
    } else {
      close();
    }
  }, [data]);
  const onMove = () => {
    if (socket) socket.invoke('changeCity');
    close();
  };
  const buttonText = () => (
    <>
      {data?.movingCost > 0 ? (
        <>
          {data?.movingCost} <Rubble />
        </>
      ) : (
        'Переехать'
      )}
    </>
  );
  const balanceValue = data?.balance?.value
    ? parseFloat(data?.balance?.value.toFixed(2)).toLocaleString('ru')
    : '';
  // TODO: текст описание в зависимости от стоимости переезда
  return (
    <Modal overlayClassName="modal" closeTimeoutMS={300} className="modal__frame" isOpen={active}>
      {/* TODO: Картинка для переезда */}
      <img src={bgLayer} alt="" className="modal__image" />
      {data?.movingCost < 0 && <h2 className="modal__title">Вы попали на карточку переезд</h2>}
      <div className="modal__text">У вас есть возможность переехать в соседний город</div>
      <div className="modal__params">
        <div className="modal__paramsItem">
          <div className="modal__paramsKey">{data?.name}</div>
          <div className="modal__paramsValue">
            <Rating activeStars={data?.rating?.value || 0} />
          </div>
        </div>
        <div className="modal__paramsItem">
          <div className="modal__paramsKey">Мэр</div>
          <div className="modal__paramsValue">{data?.mayorInfo?.name}</div>
        </div>
        <div className="modal__paramsItem">
          <div className="modal__paramsKey">Казна </div>
          <div className="modal__paramsValue">
            {balanceValue} <Rubble />
          </div>
        </div>
        <div className="modal__paramsItem">
          <div className="modal__paramsKey">Население</div>
          <div className="modal__paramsValue">{data?.population?.value} чел.</div>
        </div>
        <div className="modal__paramsItem">
          <div className="modal__paramsKey">Налог</div>
          <div className="modal__paramsValue">{data?.taxSystemInfo?.name}</div>
        </div>
      </div>
      <div className="modal__buttons">
        <Button onClick={onMove} disabled={buttonDisabled}>
          {buttonText()}
        </Button>
        <Button onClick={close}>Остаться</Button>
      </div>
      <div className="modal__timer">
        <span>Сделайте ход </span>
        <Timer expiryTimestamp={headerEndTime} className="modal__timer--value" />
      </div>
    </Modal>
  );
};

export default MovingContent;
