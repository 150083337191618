import { PROFILE_ACTION_TYPES } from 'modules/game/redux/profile/reducer';

export const GetProfileInfoSuccessAction = (payload) => ({
  type: PROFILE_ACTION_TYPES.GET.SUCCESS,
  payload
});

export const ResetAction = () => ({
  type: PROFILE_ACTION_TYPES.RESET_STATE
});
