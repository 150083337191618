import React, { useEffect, useState } from 'react';
import ZIcon from 'components/common/zIcon';
import { useDispatch } from 'react-redux';
import { updateVoteAction } from '../../redux/actions';

export const CouncilSolution = (props) => {
  const { infoSolution = {}, isActive } = props;
  const { id, used, name, increaseIndicatorValue, description, cost } = infoSolution;
  const dispatch = useDispatch();
  const [isShowDesc, setIsShowDesc] = useState(false);

  useEffect(() => () => setIsShowDesc(false), []);

  const priceValue = cost ? parseFloat(cost.toFixed(2)).toLocaleString('ru') : '';
  const costContent = () =>
    used ? (
      'Решено'
    ) : (
      <>
        {`${priceValue} МЛН`} <ZIcon />
      </>
    );

  const handleToogleDesc = (idSolution) => () => {
    dispatch(updateVoteAction(idSolution));
    setIsShowDesc(!isShowDesc);
  };

  const valueIndicator =
    increaseIndicatorValue < 0 ? `– ${increaseIndicatorValue * -1}` : `+ ${increaseIndicatorValue}`;

  return (
    <button
      key={id}
      onClick={handleToogleDesc(id)}
      type="button"
      className={`councilInfo__item ${isActive}`}
      disabled={used}
    >
      <span className="councilInfo__itemTitle">
        <span className="councilInfo__itemTitle--value">{name}</span>
        <span className="councilInfo__itemRow">
          <span className="councilInfo__itemValue">{valueIndicator}</span>
          <span className="councilInfo__itemPrice">{costContent()}</span>
        </span>
      </span>

      {isShowDesc && <span className="councilInfo__itemDesc">{description}</span>}
    </button>
  );
};
