import React from 'react';
import './index.scss';

const Button = (props) => {
  const { children, filled, color = '', style, ...other } = props;

  const classList = `button ${color} ${filled ? 'filled' : ''}`;
  return (
    <button style={style} className={classList} type="button" {...other}>
      {children}
    </button>
  );
};

export default Button;
