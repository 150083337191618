import React, { useEffect, useState } from 'react';
import Button from 'components/common/button';
import { useSelector } from 'react-redux';
import Modal from 'react-modal';
import Effect from 'components/effect';
import PersonCard from 'components/personCard';
import { withLoading, userSelector } from 'redux/selectors';
import './index.scss';

const GreetingsContent = (props) => {
  const { data } = props;
  const { firstName, surname, photoPath } = useSelector(userSelector);
  const { isLoading } = useSelector(withLoading('user'));
  const [active, setActive] = useState(false);
  const close = () => {
    setActive(false);
  };
  const open = () => {
    setActive(true);
  };
  useEffect(() => {
    if (data) {
      open();
    }
  }, [data]);

  return (
    <Modal
      onRequestClose={close}
      overlayClassName="modal"
      closeTimeoutMS={300}
      className="modal__frame"
      isOpen={active}
    >
      <div className="modal__greetings">
        <h2 className="modal__title">Мы рады тебя видеть в нашей игре «Город, открытый тобой»</h2>
        <div className="modal__text">
          Принимать решения для улучшения своего города и повышения качества жизни для себя и своих
          друзей
        </div>
        <div className="mayor">
          <div className="mayor__row">
            <div className="personCard">
              <div className="personCard__header">
                <PersonCard
                  isLoading={isLoading}
                  firstName={firstName}
                  surname={surname}
                  photoPath={photoPath}
                />
              </div>

              <div className="personCard__info">
                <Effect type="buff">Ваш город: {data?.city}</Effect>
              </div>
            </div>
          </div>
        </div>
        <div className="modal__buttons">
          <Button onClick={close}>Понятно</Button>
        </div>
      </div>
    </Modal>
  );
};

export default GreetingsContent;
