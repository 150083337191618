import React from 'react';

const GameColsName = () => (
  <div className="chooseGame__headers">
    <div className="chooseGame__col">Название игры</div>
    <div className="chooseGame__col">Дата и время игры</div>
    <div className="chooseGame__col">Время игры</div>
    <div className="chooseGame__col">Ходы</div>
    <div className="chooseGame__col">Модератор</div>
    <div className="chooseGame__col">Игроки</div>
  </div>
);

export default GameColsName;
