import React from 'react';
import iconUser from 'assets/img/personIcon.svg';

import './index.scss';

const PersonCard = ({ firstName, surname, photoPath, isLoading = false }) => {
  const generateFullName = () => {
    if (surname) {
      return `${firstName} ${surname}`;
    }
    return firstName;
  };
  const generateAvatar = () => {
    if (photoPath) {
      return (
        <img
          src={`${process.env.REACT_APP_API_URL}${photoPath}`}
          alt=""
          className="personCard__image"
        />
      );
    }
    // TODO: PASHA добавь сюда картинку заглушку если у зверя нет аватарки;
    return (
      <div className="personCard__imageBox">
        <img src={iconUser} alt="profileIcon" className="personCard__image" />
      </div>
    );
  };
  return (
    <>
      {isLoading ? '' : generateAvatar()}
      {isLoading ? '' : <h3 className="personCard__name">{generateFullName()}</h3>}
    </>
  );
};
export default PersonCard;
