import React from 'react';
import moment from 'moment';
import Modal from 'react-modal';
import { ModalProvider } from 'react-modal-hook';
import { Provider } from 'react-redux';
import Layout from 'components/layouts';
import { store } from './redux';
import 'moment/locale/ru';

Modal.setAppElement('#root'); // important!
moment.locale('ru'); // !

const App = () => (
  <Provider store={store}>
    <ModalProvider>
      <Layout />
    </ModalProvider>
  </Provider>
);

export default App;
