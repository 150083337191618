import React from 'react';

export const Message = ({ time, author, content }) => (
  <div className="chat__message">
    <span className="chat__messageTime">{time}</span>
    <div className="chat__messageContent">
      <span className="chat__messageName">{author}</span>
      <span className="chat__messageContent">{content}</span>
    </div>
  </div>
);
