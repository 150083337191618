/* eslint-disable no-nested-ternary */
import React from 'react';
import { useSelector } from 'react-redux';
import commentIcon from 'assets/img/publicComment.svg';
import { socketSelector } from 'redux/selectors';

export const NewsItem = ({ time, desc, type }) => {
  const socket = useSelector(socketSelector);

  const handleStartPublicVote = () => {
    if (socket) {
      socket.invoke('getPublicHearingAnnouncement');
    }
  };

  const handleStartVotingPriorityObjects = () => {
    if (socket) {
      socket.invoke('getVotingPriorityObjects');
    }
  };

  const isNewsEvent = type === 'PUBLICHEARING' || type === 'VOTINGPRIORITYOBJECTS';
  const handleStart =
    type === 'PUBLICHEARING'
      ? handleStartPublicVote
      : type === 'VOTINGPRIORITYOBJECTS'
      ? handleStartVotingPriorityObjects
      : false;
  const isPublicCommentsIcon = isNewsEvent && <img src={commentIcon} alt="comment" />;
  const isPublicCommentsBtn = isNewsEvent && (
    <button type="button" className="news__more" onClick={handleStart}>
      Подробнее
    </button>
  );

  return (
    <div className="news__item">
      {isPublicCommentsIcon}
      <div className="news__time">{time}</div>
      <div className="news__content">
        {desc}
        {" "}
        {isPublicCommentsBtn}
      </div>
    </div>
  );
};
