/* eslint-disable no-nested-ternary */
import HOR from 'redux/HOR';
import {ActionTypes} from 'redux/utils/actionCreator';

const { pipeHigherOrderReducers, withResetState, withLoadable } = HOR;

export const NEWS_ACTION_TYPES = new ActionTypes('NEWS', ['SET']).listAT().getActionTypes();

const initialState = {
  newsTabs: [],
  currentCity: {},
  diffrentCity: {}
};

const handleAction = {
  [NEWS_ACTION_TYPES.GET.SUCCESS]: (state, params) => {
    if (params.mainMassMedia) {
      return {
        ...state,
        currentCity: params,
      };
    }
    return {
      ...state,
      diffrentCity: params,
    };
  }
};

const newsReducer = (state = initialState, action) =>
  handleAction[action.type] ? handleAction[action.type](state, action.payload) : state;


export default pipeHigherOrderReducers(
  withResetState(NEWS_ACTION_TYPES.RESET_STATE, initialState),
  withLoadable({
    isLoadingActionType: [NEWS_ACTION_TYPES.GET.START],
    successActionType: [NEWS_ACTION_TYPES.GET.SUCCESS],
    errorActionType: [NEWS_ACTION_TYPES.GET.ERROR]
  })
)(newsReducer);
