import { COUNCIL_ACTION_TYPES } from 'modules/council/redux/reducer';

export const GetCouncilInfoSuccessAction = (payload) => ({
  type: COUNCIL_ACTION_TYPES.GET.SUCCESS,
  payload
});

export const updateVoteAction = (payload) => ({
  type: COUNCIL_ACTION_TYPES.UPDATE_VOTE,
  payload
});

export const UpdateCouncilInfoAction = (payload) => ({
  type: COUNCIL_ACTION_TYPES.UPDATE_BALANCE,
  payload
});

export const ResetAction = () => ({
  type: COUNCIL_ACTION_TYPES.RESET_STATE
});
