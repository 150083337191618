/* eslint-disable no-lonely-if,react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { generateElemsByNumber } from 'utils/generators';
import { useSelector } from 'react-redux';
import { getDiceIsNextStep, getDiceValue } from 'modules/game/redux/diceGame/selectors';
import './index.scss';

export const ANIMATE_FULL_TIME_MS = 2000;
export const DELAY_BEFORE_ANIMATION_MS = 6000;
export const DELAY_BEFORE_FULL_ANIMATION_MS = 8000;
export const ANIMATE_TICK_MS = 100;
export const ANIMATE_TIME_MS = 600;

export const diceTemplate = [
  <div className="dice__value">%</div>,
  <div className="dice__value">$</div>,
  <div className="dice__value">#</div>,
  <div className="dice__value">+</div>,
  <div className="dice__value">=</div>,
  <div className="dice__value">?</div>
];

const Dice = () => {
  const { diceValue } = useSelector(getDiceValue);
  const isNextStep = useSelector(getDiceIsNextStep);
  const [dotView, setDotView] = useState('');
  const [isDisableDice, setIsDisableDice] = useState(true);
  const [animateTimerId, setAnimateTimerId] = useState(null);
  const [animateStartTime, setAnimateStartTime] = useState(null);
  const diceClass = diceValue?.value ? `dice__value--${diceValue?.value}` : '';
  const isEmptyDice = diceValue?.value ? (
    dotView || <div className="dice__value">?</div>
  ) : (
    <div className="dice__value">?</div>
  );

  const dicecol = generateElemsByNumber(diceValue?.value, '.');
  const diceView = () => (
    <div className={`dice__value ${diceClass}`}>
      {dicecol.map((_, id) => (
        //  eslint-disable-next-line react/no-array-index-key
        <span className="dice__dot" key={`dice-key-${id}`} />
      ))}
    </div>
  );
  const animate = (template, animateTimer) => {
    let commonAnimateTime = animateTimer;
    const animateLoop = () => {
      const animationTemplate = [...template];

      const an = setInterval(() => {
        setDotView(animationTemplate.shift());
        if (animationTemplate.length === 0) {
          clearInterval(an);
        }
        if (animationTemplate.length === 0 && commonAnimateTime > 0) {
          commonAnimateTime -= ANIMATE_TIME_MS;
          animateLoop();
        }
        if (commonAnimateTime <= 0) {
          animationTemplate.splice(0, animationTemplate.length);
          setDotView(diceView);
        }
      }, ANIMATE_TICK_MS);
    };
    animateLoop();
  };

  useEffect(() => {
    if (isNextStep) {
      if (diceValue?.value) {
        setAnimateStartTime(performance.now());
        const timeoutId = setTimeout(() => {
          setIsDisableDice(true);
          animate(diceTemplate, ANIMATE_FULL_TIME_MS);
        }, DELAY_BEFORE_ANIMATION_MS);
        setAnimateTimerId(timeoutId);
        setIsDisableDice(false);
        setDotView('');
      }
    } else {
      setIsDisableDice(true);
      setDotView(diceView);
    }
  }, [diceValue]);

  const handleClickDice = () => {
    const timeLeft = DELAY_BEFORE_FULL_ANIMATION_MS - (performance.now() - animateStartTime);
    clearTimeout(animateTimerId);

    animate(diceTemplate, timeLeft);

    setIsDisableDice(true);
  };

  return (
    <div className="dice">
      {!isDisableDice && <div className="dice__help">Ваш ход, нажмите на кубик</div>}
      <div className="dice__box" disabled={isDisableDice}>
        <button
          type="button"
          className="dice__btn"
          onClick={handleClickDice}
          disabled={isDisableDice}
        />
        <div className="dice__cube">{isEmptyDice}</div>
      </div>
    </div>
  );
};
export default Dice;
