import { GAMELIST_ACTION_TYPES } from 'modules/gameList/redux/reducer';

export const GetGamesAction = () => ({
  type: GAMELIST_ACTION_TYPES.GET.START
});

export const RegisterOnGameAction = (payload) => ({
  type: GAMELIST_ACTION_TYPES.POST.START,
  payload
});

export const ResetAction = () => ({
  type: GAMELIST_ACTION_TYPES.RESET_STATE
});
