import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { NotificationSelector } from '../redux/selectors';
import NotificationView from './notificationView';

const Notification = () => {
  const [activeClass, setActiveClass] = useState('');
  const { message, status, title, isCloseTimeout } = useSelector(NotificationSelector);
  const onClose = () => {
    setActiveClass('');
  };

  useEffect(() => {
    setActiveClass(status);
  }, [status]);

  return (
    <>
      {activeClass && (
        <NotificationView
          activeClass={activeClass}
          message={message}
          title={title}
          onClose={onClose}
          isCloseTimeout={isCloseTimeout}
        />
      )}
    </>
  );
};

export default Notification;
