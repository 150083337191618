import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import useNotification from 'hooks/useNotification.hook';
import { ResetAction as gameResetAction } from 'modules/game/redux/enterGame/actions';
import { gameListSelector } from '../redux/selectors';
import GameListItem from './gameListItem';
import SkeletonGameItem from './skeletonGameItem';
import GameColsName from './gameColsName';
import GamesEmpty from './gamesEmpty';
import { GetGamesAction } from '../redux/actions';

const GameListLoader = () => (
  <>
    <SkeletonGameItem />
    <SkeletonGameItem />
    <SkeletonGameItem />
    <SkeletonGameItem />
  </>
);

const GameList = () => {
  const games = useSelector(gameListSelector);


  const dispatch = useDispatch();
  useNotification('gameList');
  useNotification('enterGame');
  useEffect(() => {
    dispatch(GetGamesAction());

    return () => {
      dispatch(gameResetAction());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const generateGameList = () => {
    if (games && games.length === 0) {
      return <GamesEmpty />;
    }

    return (
      <div className="chooseGame__list">
        <GameColsName />
        {games ? (
          games.map((item) => <GameListItem key={item.id} rowData={item} />)
        ) : (
          <GameListLoader />
        )}
      </div>
    );
  };
  return <>{generateGameList()}</>;
};

export default GameList;
