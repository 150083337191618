import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import { headerInfoSelector } from 'modules/game/redux/header/selectors';
import Timer from 'components/timer';
import { APP_ROUTES } from 'constants/APP_ROUTES';

const GameEndTimer = () => {
  const history = useHistory();
  const headerInfo = useSelector(headerInfoSelector);
  const [isOpen, setIsOpen] = useState(false);

  const handleClose = () => setIsOpen(false);

  useEffect(() => {
    if (headerInfo?.event === 'WAITINGEND') {
      setIsOpen(true);
    }
    if (headerInfo?.event === 'ENDED') {
      history.push(APP_ROUTES.choosegame);
    }
  }, [headerInfo]);

  return (
    <Modal
      overlayClassName="modal"
      closeTimeoutMS={300}
      className="modal__frame modal__frame--endTimer"
      isOpen={isOpen}
    >
      <h2 className="modal-end__title">
        Игра звершится через{' '}
        <Timer expiryTimestamp={headerInfo?.correctEndTimeEvent} className="modal__end--value" />
      </h2>

      <button type="button" className="modal-end__close" onClick={handleClose}>
        Понятно
      </button>
    </Modal>
  );
};

export default GameEndTimer;
