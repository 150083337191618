import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { PublicAuditionItem } from 'modules/game/components/publicAudition/publicAuditionItem';
import { useSelector } from 'react-redux';
import { getPublicAuditionInfo } from 'modules/game/redux/publicAudition/selectors';
import { socketSelector } from 'redux/selectors';
import Timer from 'components/timer';
import { headerInfoSelector } from 'modules/game/redux/header/selectors';
import useResize from 'hooks/useResize.hook';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Pagination } from 'swiper/core';

export const PublicAuditionContent = () => {
  const socket = useSelector(socketSelector);
  const data = useSelector(getPublicAuditionInfo);
  const headerInfo = useSelector(headerInfoSelector);
  const { isMobile, isTablet } = useResize();
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    setIsActive(data?.active);
  }, [data]);

  const generateIsMobile = () => {
    const handleVoteSolution = (id) => () => {
      socket.invoke('choosePublicHearingDecision', id);
      setIsActive(false);
    };
    if (isTablet || isMobile) {
      SwiperCore.use([Pagination]);
      return (
        <Swiper
          className="audition__slider"
          spaceBetween={10}
          slidesPerView="auto"
          pagination={{ clickable: true }}
        >
          {data?.decisions?.map((el) => (
            <SwiperSlide key={`audition-${el?.id}`} className="audition__slide">
              <PublicAuditionItem auditionInfo={el} onClick={handleVoteSolution(el?.id)} />
            </SwiperSlide>
          ))}
        </Swiper>
      );
    }

    return data?.decisions?.map((el) => (
      <PublicAuditionItem
        key={`audition-${el?.id}`}
        auditionInfo={el}
        onClick={handleVoteSolution(el?.id)}
      />
    ));
  };

  return (
    <Modal
      overlayClassName="modal"
      closeTimeoutMS={300}
      className="modal__frame modal__frame--public"
      isOpen={isActive}
    >
      <h2 className="modal__title modal__title--public">Публичные слушания</h2>

      <div className="modal__auditions">{generateIsMobile()}</div>

      <div className="modal__timer">
        <span>Сделайте ход </span>
        <Timer expiryTimestamp={headerInfo?.correctEndTimeEvent} className="modal__timer--value" />
      </div>
    </Modal>
  );
};
