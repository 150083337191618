import React from 'react';
import GameList from 'modules/gameList/components';
import { ExitButton } from 'components/exitBtn';
import Logo from '../../components/logo';
import './index.scss';

const ChooseGame = () => (
  <section className="chooseGame">
    <header className="chooseGame__header">
      <div className="chooseGame__wrapper wrapper">
        <Logo />

        <ExitButton />
      </div>
    </header>
    <div className="chooseGame__body">
      <div className="wrapper">
        <h1 className="chooseGame__title">
          Запишись бесплатно на игру «Город, открытый тобой» за счёт платформы местной вовлеченности
          #МестоВстречи
        </h1>
        <GameList />
      </div>
    </div>
  </section>
);
export default ChooseGame;
