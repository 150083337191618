import React from 'react';
import moment from 'moment';
import { numWord } from 'utils/generators';
import { RegisteredButton, UnregisteredButton } from './buttons';

const GameListItem = (props) => {
  const {
    rowData: {
      id,
      totalPlayingTime,
      totalNumberMoves,
      maxNumberPlayers,
      numberRegisteredPlayers,
      registered,
      moderators,
      startDateTime,
      name
    }
  } = props;
  const date = moment
    .utc(startDateTime * 1000)
    .local()
    .format('DD.MM.YYYY');
  const dateTime = moment
    .utc(startDateTime * 1000)
    .local()
    .format('HH:mm');
  // Register button @gameID = id
  const actionButton = (gameID) =>
    registered ? <RegisteredButton id={gameID} /> : <UnregisteredButton id={gameID} />;

  const timeTemplate = (playingSeconds) => {
    const d = moment.duration(playingSeconds, 'seconds');
    const hours = d.hours();
    const minutes = d.minutes();
    const hoursTemplate = hours ? `${hours} ${numWord(hours, ['час', 'часа', 'часов'])} ` : '';
    const minutesTemplate = minutes
      ? `${minutes} ${numWord(minutes, ['минута', 'минуты', 'минут'])}`
      : '';
    return `${hoursTemplate}${minutesTemplate}`;
  };

  const moveTemplate = (playingMoves) =>
    `${playingMoves} ${numWord(playingMoves, ['ход', 'хода', 'ходов'])}`;

  const playersTemplate = (registeredPlayers, totalPlayers) =>
    `${registeredPlayers}/${totalPlayers} ${numWord(totalPlayers, [
      'человек',
      'человека',
      'человек'
    ])}`;
  const moderatorTemplate = (gameModerators) => (
    <>
      {gameModerators &&
        gameModerators.length > 0 &&
        gameModerators.map((item) => (
          <span className="chooseGame__name" key={item}>
            {item}
          </span>
        ))}
    </>
  );

  return (
    // eslint-disable-next-line
    <div onClick={() => sessionStorage.setItem('gameId', id)} className="chooseGame__item">
      <div className="chooseGame__col chooseGame__col--title">{name}</div>
      <div className="chooseGame__col chooseGame__col--type">
        <span className="chooseGame__date">{date}</span>
        <span className="chooseGame__time">{dateTime}</span>
      </div>
      <div className="chooseGame__col chooseGame__col--duration">
        {timeTemplate(totalPlayingTime)}
      </div>
      <div className="chooseGame__col chooseGame__col--steps">{moveTemplate(totalNumberMoves)}</div>
      <div className="chooseGame__col chooseGame__col--admin">{moderatorTemplate(moderators)}</div>
      <div className="chooseGame__col chooseGame__col--players">
        {playersTemplate(numberRegisteredPlayers, maxNumberPlayers)}
      </div>
      {actionButton(id)}
    </div>
  );
};

export default GameListItem;
