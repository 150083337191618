import HOR from 'redux/HOR';
import { ActionTypes } from 'redux/utils/actionCreator';

const { pipeHigherOrderReducers, withLoadable, withResetState } = HOR;
export const PROFILE_ACTION_TYPES = new ActionTypes('PROFILE').listAT().getActionTypes();

const initialState = {};
const handleAction = {
  [PROFILE_ACTION_TYPES.GET.SUCCESS]: (state, params) => ({
    ...state,
    ...params
  })
};

const reducer = (state = initialState, action) =>
  handleAction[action.type] ? handleAction[action.type](state, action.payload) : state;

export default pipeHigherOrderReducers(
  withResetState(PROFILE_ACTION_TYPES.RESET_STATE, initialState),
  withLoadable({
    isLoadingActionType: [PROFILE_ACTION_TYPES.GET.START],
    successActionType: [PROFILE_ACTION_TYPES.GET.SUCCESS],
    errorActionType: [PROFILE_ACTION_TYPES.GET.ERROR]
  })
)(reducer);
