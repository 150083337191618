import { ActionTypes } from 'redux/utils/actionCreator';
import HOR from 'redux/HOR';

const { pipeHigherOrderReducers, withLoadable, withResetState } = HOR;
export const PUBLIC_AUDITION_ACTION_TYPES = new ActionTypes('AUDITION_ACTION')
  .listAT()
  .getActionTypes();

const initialState = {
  infoAction: {}
};

const handleAction = {
  [PUBLIC_AUDITION_ACTION_TYPES.GET.SUCCESS]: (state, params) => ({
    ...state,
    infoAction: params
  })
};

const reducer = (state = initialState, action) =>
  handleAction[action.type] ? handleAction[action.type](state, action.payload) : state;

export default pipeHigherOrderReducers(
  withResetState(PUBLIC_AUDITION_ACTION_TYPES.RESET_STATE, initialState),
  withLoadable({
    isLoadingActionType: [PUBLIC_AUDITION_ACTION_TYPES.GET.START],
    successActionType: [PUBLIC_AUDITION_ACTION_TYPES.GET.SUCCESS],
    errorActionType: [PUBLIC_AUDITION_ACTION_TYPES.GET.ERROR]
  })
)(reducer);
