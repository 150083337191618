import { combineReducers } from 'redux';
// global
import UserReducer from 'redux/reducers/user';
import SocketReducer from 'redux/reducers/socket';
import tabBarReducer from 'redux/reducers/tabBar';
// modules
import publicActionReducer from 'modules/game/redux/publicAction/reducer';
import auditionReducer from 'modules/game/redux/publicAudition/reducer';
import NotificationReducer from 'modules/notifications/redux/reducers';
import GameListReducer from 'modules/gameList/redux/reducer';
import GameUserIdReducer from 'modules/game/redux/enterGame/reducer';
import ProfileInfoReducer from 'modules/game/redux/profile/reducer';
import CouncilReducer from 'modules/council/redux/reducer';
import HeaderInfoReducer from 'modules/game/redux/header/reducer';
import CityInfoReducer from 'modules/game/redux/city/reducer';
import chatReducer from 'modules/chat/redux/reducer';
import newsReducer from 'modules/news/redux/reducer';
import priorityObjectsReducer from 'modules/game/redux/voteAreas/reducer';
import DiceReducer from 'modules/game/redux/diceGame/reducer';

const appReducer = combineReducers({
  city: CityInfoReducer,
  notifications: NotificationReducer,
  user: UserReducer,
  socket: SocketReducer,
  gameList: GameListReducer,
  enterGame: GameUserIdReducer,
  profile: ProfileInfoReducer,
  council: CouncilReducer,
  header: HeaderInfoReducer,
  chat: chatReducer,
  news: newsReducer,
  tabBar: tabBarReducer,
  publicAction: publicActionReducer,
  audition: auditionReducer,
  priorityObjects: priorityObjectsReducer,
  dice: DiceReducer
});
export default appReducer;
