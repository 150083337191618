import React from 'react';
import Button from 'components/common/button';

export const PublicAuditionItem = ({
  auditionInfo: { title, description, effects } = {},
  onClick
}) => {
  const generateEffects = () =>
    effects?.map(({ value, problemName }, index) => {
      if (index !== effects.length - 1) {
        return `${value} ${problemName}, `;
      }
      return `${value} ${problemName}`;
    });

  return (
    <div className="public-solution">
      <div className="public-solution__title">{title}</div>
      <div className="public-solution__desc">{description}</div>
      <div className="public-solution__effects">{generateEffects()}</div>
      <Button filled onClick={onClick}>
        Голосовать
      </Button>
    </div>
  );
};
