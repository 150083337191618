import React from 'react'
import Button from 'components/common/button'

export const AreaItem = ({info, onClick}) => {

  const generateEffects = () => info?.effects?.map(el => {
    const effectValue = el?.value > 0 ? `+${el?.value}` : el?.value;

    return <div className="area__effect">{`${effectValue} ${el?.problemName}`}</div>
  })

  return (
    <div className="area">
      <img
        src={`${process.env.REACT_APP_API_URL || ''}${info?.picturePath}`}
        alt=""
        className="area__img"
      />
      <h3 className="area__title">{info?.title}</h3>
      <p className="area__desc">{info?.description}</p>
      <div className="area__effects">{generateEffects()}</div>
      <Button filled onClick={onClick}>Голосовать</Button>
    </div>
  );
}
