import React from 'react'
import Rating from "components/rating";

export const GameEndCity = ({data}) => {

  const winnerClass = () => data.winner ? "win" : "";

  return (
    <div className={`city-end ${winnerClass()}`}>
      <h3 className="city-end__title">{data.name}</h3>
      <div className="city-end__stats">
        <div className="city-end__statsItem">
          <div className="city-end__statsTitle">Рейтинг города</div>
          <div className="city-end__statsValue"><Rating activeStars={data?.rating}/></div>
        </div>
      </div>
    </div>
  )
}