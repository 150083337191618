import { NOTIFICATIONS_ACTION_TYPES } from '../reducers';

export const NotificationOpenAction = (payload) => ({
  type: NOTIFICATIONS_ACTION_TYPES.OPEN,
  payload
});

export const NotificationResetAction = () => ({
  type: NOTIFICATIONS_ACTION_TYPES.RESET_STATE
});
