import React from 'react';
import RouterLink from 'components/router/link';
import Logo from '../../components/logo';
import './index.scss';

const noMatch = () => (
  <>
    <div className="noMatch">
      <header className="noMatch__header">
        <div className="wrapper">
          <Logo />
        </div>
      </header>
      <div className="noMatch__box">
        <div className="noMatch__frame">
          <h1 className="noMatch__title">404</h1>
          <span className="noMatch__text">
            Запрашиваемая страница не найдена <br /> или была удалена
          </span>
          <RouterLink to="/">
            <button type="button" className="noMatch__linkBack">
              Вернуться на сайт
            </button>
          </RouterLink>
        </div>
      </div>
    </div>
  </>
);

export default noMatch;
