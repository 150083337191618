import React, {useEffect, useState} from 'react'
import {useSelector} from "react-redux";
import {socketSelector} from "redux/selectors";
import {GameEndContent} from "./gameEndContent";
import "./index.scss";



export const GameEnd = () => {


  const socket = useSelector(socketSelector);
  const [endGame, setEndGame] = useState("");

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (socket) {
      socket.on("gameResult", data => {
        // TODO: to redux
        setEndGame(data);
      });
      return () => {
        socket.off("gameResult");
      }
    }
  }, [socket]);

  return (
    <GameEndContent data={endGame}/>
  )
}
