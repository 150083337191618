/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { socketSelector } from 'redux/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { GetInfoVoteAreasAction } from 'modules/game/redux/voteAreas/actions';
import { VoteAreasContent } from './voteAreasContent';

const VoteAreasAction = () => {
  const socket = useSelector(socketSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    if (socket) {
      socket.on('votingPriorityObjects', (data) => {
        dispatch(GetInfoVoteAreasAction(data));
      });
    }

    return () => {
      if (socket) {
        socket.off('votingPriorityObjects');
      }
    };
  }, [socket]);

  return <VoteAreasContent />;
};

export default VoteAreasAction;
