import React, { useEffect } from 'react';
import { useTimer } from 'react-timer-hook';

const Timer = (props) => {
  const { expiryTimestamp, ...other } = props;
  const dateExpiryTimestamp = new Date(expiryTimestamp);
  const { seconds, minutes, hours, days, start, pause, restart } = useTimer({
    expiryTimestamp: dateExpiryTimestamp,
    onExpire: () => pause()
  });
  useEffect(() => {
    if (expiryTimestamp) {
      start();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (expiryTimestamp) {
      restart(expiryTimestamp);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expiryTimestamp]);

  const generateTimer = () => {
    if (days)
      return (
        <>
          {days}:{hours}:{minutes}:{seconds}
        </>
      );
    if (!days && hours)
      return (
        <>
          {hours}:{minutes}:{seconds}
        </>
      );
    return (
      <>
        {minutes}:{seconds}
      </>
    );
  };
  return <div {...other}>{generateTimer()}</div>;
};

export default Timer;
