import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { socketSelector } from 'redux/selectors';
import GreetingsContent from './greetingsContent';

const Greeting = () => {
  const socket = useSelector(socketSelector);
  const [welcome, setWelcome] = useState('');

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (socket) {
      socket.on('welcome', (data) => {
        // TODO: to redux
        setWelcome(data);
      });
      return () => {
        socket.off('welcome');
      };
    }
  }, [socket]);

  return <GreetingsContent data={welcome} />;
};

export default Greeting;
